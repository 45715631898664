import React from 'react';
import { TrendingUp, Clock, Code, Star } from 'lucide-react';

export default function Results() {
  const metrics = [
    {
      icon: Clock,
      title: "Temps de développement",
      value: "4 mois",
      subtitle: "au lieu de 6 prévus"
    },
    {
      icon: Code,
      title: "Réutilisation de code",
      value: "60%",
      subtitle: "de code partagé"
    },
    {
      icon: TrendingUp,
      title: "Maintenance",
      value: "-40%",
      subtitle: "de temps sur les mises à jour"
    },
    {
      icon: Star,
      title: "Performance",
      value: "95",
      subtitle: "Score Lighthouse moyen"
    }
  ];

  const improvements = [
    {
      label: "Lignes de code par site",
      before: "15K",
      after: "6K + 5K partagées"
    },
    {
      label: "Couverture de tests",
      before: "30%",
      after: "85%"
    },
    {
      label: "Temps de build",
      before: "5min",
      after: "2min"
    }
  ];

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Résultats obtenus</h2>

      <div className="grid md:grid-cols-4 gap-6">
        {metrics.map((metric, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-lg">
            <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
              <metric.icon className="h-6 w-6 text-primary" />
            </div>
            <div className="text-2xl font-bold text-primary">{metric.value}</div>
            <div className="text-sm text-gray-600">{metric.title}</div>
            <div className="text-xs text-gray-500">{metric.subtitle}</div>
          </div>
        ))}
      </div>

      <div className="bg-white rounded-xl p-8 shadow-lg">
        <h3 className="text-xl font-semibold text-primary mb-6">Améliorations techniques</h3>
        <div className="space-y-4">
          {improvements.map((improvement, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="w-1/3 text-gray-600">{improvement.label}</div>
              <div className="flex-1 flex items-center space-x-4">
                <div className="bg-red-100 px-3 py-1 rounded-full text-red-700">
                  {improvement.before}
                </div>
                <div className="text-gray-400">→</div>
                <div className="bg-green-100 px-3 py-1 rounded-full text-green-700">
                  {improvement.after}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}