import React from 'react';
import { Check } from 'lucide-react';

const benefits = [
  "Une livraison plus rapide",
  "Une meilleure qualité globale",
  "Des coûts réduits",
  "Une maintenance simplifiée"
];

export default function Conclusion() {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-secondary">Conclusion</h2>
      
      <div className="bg-white rounded-xl p-8 shadow-lg">
        <p className="text-gray-600 mb-6">
          Ce projet démontre l'importance d'une architecture bien pensée pour des sites multiples. 
          L'investissement initial dans une base de code solide et réutilisable a permis :
        </p>
        
        <div className="grid sm:grid-cols-2 gap-4">
          {benefits.map((benefit, index) => (
            <div 
              key={index}
              className="flex items-center space-x-3 bg-green-50 p-4 rounded-lg"
            >
              <Check className="h-5 w-5 text-green-500 flex-shrink-0" />
              <span className="text-green-700">{benefit}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}