import React from 'react';
import type { LucideIcon } from 'lucide-react';

interface SystemCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  features: string[];
  color: string;
  index: number;
}

export default function SystemCard({ icon: Icon, title, description, features, color, index }: SystemCardProps) {
  return (
    <div className="group relative transform hover:-translate-y-2 transition-all duration-300">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-gradient-highlight opacity-5 rounded-xl transform rotate-1" />
      
      {/* Card content */}
      <div className="relative bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-highlight/10">
        {/* Step number */}
        <div className="absolute -top-4 left-8 bg-gradient-to-r from-highlight to-highlight-dark text-white w-8 h-8 rounded-full flex items-center justify-center text-sm font-bold shadow-lg">
          {index}
        </div>

        {/* Icon */}
        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6">
          <div className="absolute w-16 h-16 bg-highlight/10 rounded-xl transform rotate-45 group-hover:rotate-0 transition-transform duration-300" />
          <Icon className="relative h-8 w-8 text-highlight transform -rotate-45 group-hover:rotate-0 transition-transform duration-300" />
        </div>

        {/* Content */}
        <div className="text-center mb-6">
          <h3 className="text-2xl font-bold text-secondary group-hover:text-highlight transition-colors duration-300">
            {title}
          </h3>
          <p className="mt-2 text-gray-600">
            {description}
          </p>
        </div>

        {/* Features */}
        <ul className="space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center space-x-3">
              <span className="w-1.5 h-1.5 rounded-full bg-highlight" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>

        {/* Hover effect overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-highlight/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl pointer-events-none" />
      </div>
    </div>
  );
}