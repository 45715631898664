import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const cvAnalysisCaseStudy: CaseStudy = {
  id: 6,
  client: "Système d'analyse CV",
  before: "Traitement manuel, 30min/CV",
  after: "Analyse automatisée, 2min/CV",
  duration: "3 mois",
  testimonial: "L'automatisation nous a permis de traiter 15 fois plus de CV avec une précision remarquable. Un vrai gain de productivité.",
  image: IMAGES.caseStudies.development.cv,
  success: "Productivité x15 !"
};