import React from 'react';
import { Clock, Shield, Code, HeartHandshake } from 'lucide-react';
import GuaranteeCard from './guarantees/GuaranteeCard';
import ExclusiveGuarantee from './guarantees/ExclusiveGuarantee';

const guarantees = [
  {
    icon: Clock,
    title: "Délais Garantis ou Pénalités",
    description: "Je tiens mes promesses, sinon je paie",
    features: [
      { text: "Planning détaillé avec jalons validés ensemble" },
      { text: "Dashboard temps réel de l'avancement" },
      { text: "Système d'alerte préventif : tu es informé avant qu'un problème n'arrive" },
      { text: "Compensation financière en cas de retard de mon fait" }
    ]
  },
  {
    icon: Shield,
    title: "Budget Fixe, Gravé dans le Marbre",
    description: "Zéro mauvaise surprise, je m'y engage",
    features: [
      { text: "Devis ultra-détaillé : chaque euro est justifié" },
      { text: "Protection contractuelle contre les dépassements" },
      { text: "Paiements échelonnés sur les livrables" },
      { text: "Garantie 'satisfait ou remboursé' sur le premier sprint" }
    ]
  },
  {
    icon: Code,
    title: "Code Professionnel Certifié",
    description: "Une base solide pour ta croissance",
    features: [
      { text: "Tests automatisés couvrant 90% du code" },
      { text: "Documentation technique exhaustive" },
      { text: "Code review par des experts seniors" },
      { text: "Accès total au code source et à l'historique Git" }
    ]
  },
  {
    icon: HeartHandshake,
    title: "Support VIP Post-Livraison",
    description: "Je ne t'abandonne pas après la mise en prod",
    features: [
      { text: "1 mois de support prioritaire offert" },
      { text: "Correction des bugs sous 24h-72h selon criticité" },
      { text: "Formation personnalisée de ton équipe" },
      { text: "Maintenance évolutive disponible" }
    ]
  }
];

export default function TechGuarantees() {
  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-primary/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold bg-gradient-primary text-transparent bg-clip-text inline-block mb-4">
            Garanties en béton : dors sur tes deux oreilles
          </h2>
          <p className="text-xl text-gray-600">
            Je m'engage contractuellement sur ces 4 points non négociables
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {guarantees.map((guarantee, index) => (
            <GuaranteeCard key={index} {...guarantee} />
          ))}
        </div>

        <div className="max-w-3xl mx-auto">
          <ExclusiveGuarantee />
        </div>
      </div>
    </div>
  );
}