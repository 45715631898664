import type { Experience } from '../types';

export const clicnworkExperience: Experience = {
  title: "Développeur de plugins Bubble",
  company: "clicNwork",
  period: "Janvier 2024 - Février 2024",
  location: "À distance",
  description: [
    "Développement full-stack d'une plateforme d'édition PDF multiplateforme, avec un focus particulier sur la sécurité et l'expérience utilisateur cross-browser."
  ],
  achievements: [
    "Compatibilité avec 98% des navigateurs",
    "Temps de chargement < 1.5s",
    "0 faille de sécurité détectée",
    "Conception d'une interface intuitive d'édition PDF",
    "Implémentation d'un système de sécurité robuste",
    "Optimisation des performances cross-browser",
    "Intégration transparente avec Bubble"
  ]
};