import React from 'react';

export default function ResourcesHeader() {
  return (
    <div className="text-center mb-16">
      <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text inline-block">
        Ressources gratuites
      </h1>
      <p className="mt-4 text-xl text-gray-600">
        Des outils concrets pour booster ton activité freelance
      </p>
    </div>
  );
}