import type { Experience } from '../types';

export const capygencyExperience: Experience = {
  title: "Coach pour Freelances",
  company: "Capygency",
  period: "Janvier 2024 - Présent",
  location: "À distance",
  description: [
    "En 1 mois, j'aide les freelances à obtenir des missions sans prospection, même en étant junior, grâce à mon accompagnement Malt."
  ],
  achievements: [
    "Top 2 de ma catégorie sur Malt (sur plus de 12 000 freelances)",
    "Coaching de freelances devenus 1ᵉʳ dans leurs catégories sur Malt (dev back, front, PM, PO, Scrum Master, etc.)",
    "140 freelances accompagnés avec succès",
  ]
};