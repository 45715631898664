import React from 'react';
import { Code, Clock, Target } from 'lucide-react';

const metrics = [
  {
    icon: Code,
    value: "95%",
    label: "précision d'extraction"
  },
  {
    icon: Clock,
    value: "2min",
    label: "temps d'analyse par CV"
  },
  {
    icon: Target,
    value: "-93%",
    label: "temps de traitement"
  }
];

export default function CaseStudyHeader() {
  return (
    <div className="text-center space-y-8">
      <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text">
        Système d'analyse automatique de CV
      </h1>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        De 30 minutes à 2 minutes par CV grâce à l'IA
      </p>

      <div className="grid md:grid-cols-3 gap-8">
        {metrics.map((metric, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-lg">
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
                <metric.icon className="h-6 w-6 text-primary" />
              </div>
              <div className="text-2xl font-bold text-primary">{metric.value}</div>
              <div className="text-gray-600">{metric.label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}