import React from 'react';
import { Code, Users } from 'lucide-react';
import type { PortfolioTab as TabType } from '../../../types/portfolio';

interface PortfolioTabGroupProps {
  activeTab: TabType;
  onTabChange: (tab: TabType) => void;
}

export default function PortfolioTabGroup({ activeTab, onTabChange }: PortfolioTabGroupProps) {
  return (
    <div className="flex justify-center">
      <div className="inline-flex p-1.5 space-x-1.5 bg-white/80 backdrop-blur rounded-xl shadow-lg">
        <button
          onClick={() => onTabChange('coaching')}
          className={`flex items-center px-6 py-3 rounded-lg transition-all duration-300 ${
            activeTab === 'coaching'
              ? 'bg-primary text-white shadow-md'
              : 'hover:bg-primary/5 text-gray-600'
          }`}
        >
          <Users className="h-5 w-5 mr-2" />
          Coaching Freelance
        </button>
        <button
          onClick={() => onTabChange('development')}
          className={`flex items-center px-6 py-3 rounded-lg transition-all duration-300 ${
            activeTab === 'development'
              ? 'bg-primary text-white shadow-md'
              : 'hover:bg-primary/5 text-gray-600'
          }`}
        >
          <Code className="h-5 w-5 mr-2" />
          Projets Techniques
        </button>
      </div>
    </div>
  );
}