import React from 'react';
import { TrendingDown, XCircle } from 'lucide-react';

const consequences = [
  "40K€+ de revenus perdus chaque année",
  "Des nuits blanches à chercher la prochaine mission",
  "L'impression d'être dans une impasse professionnelle",
  "La confiance qui s'érode mois après mois"
];

export default function Consequences() {
  return (
    <div className="max-w-3xl mx-auto">
      <div className="relative transform hover:-translate-y-2 transition-all duration-300">
        <div className="absolute inset-0 bg-danger-100 rounded-xl transform rotate-1 opacity-70" />
        <div className="relative bg-white rounded-xl p-8 shadow-lg border border-danger">
          <div className="flex items-center space-x-3 mb-8">
            <div className="w-12 h-12 bg-gradient-danger rounded-full flex items-center justify-center">
              <TrendingDown className="h-6 w-6 text-white" />
            </div>
            <h3 className="text-2xl font-bold bg-gradient-danger text-transparent bg-clip-text">
              Les conséquences si tu ne changes rien :
            </h3>
          </div>
          
          <div className="grid sm:grid-cols-2 gap-6">
            {consequences.map((consequence, index) => (
              <div 
                key={index}
                className="flex items-start space-x-3 bg-danger-50 p-4 rounded-lg hover:bg-danger-100 transition-colors duration-300"
              >
                <XCircle className="h-5 w-5 text-danger flex-shrink-0 mt-0.5" />
                <span className="text-gray-700">{consequence}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}