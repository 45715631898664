import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const audranCaseStudy: CaseStudy = {
  id: 3,
  client: "Audran Campoy",
  before: "Aucune mission sur Malt",
  after: "12 missions décrochées avec succès",
  duration: "2 mois",
  testimonial: "François m'a coaché pour optimiser mon profil Malt. Après une heure de formation, me voilà avec toutes les armes pour me rendre visible sur cette plateforme. Merci pour tes conseils, tes astuces, tes mots justes et ta bienveillance ! 🙏",
  image: IMAGES.caseStudies.coaching.audran,
  success: "12 missions en 2 mois !"
};