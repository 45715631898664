import React from 'react';
import { FileSearch, Linkedin } from 'lucide-react';

const audits = [
  {
    icon: FileSearch,
    title: "Audit gratuit de ton profil Malt",
    description: "Découvre les points clés à optimiser pour multiplier tes opportunités",
    features: [
      "Analyse complète de ton profil",
      "Points d'amélioration prioritaires",
      "Recommandations personnalisées",
      "Plan d'action concret"
    ],
    href: "https://www.capygency.fr/audit-malt?utm_source=francoisbehague"
  },
  {
    icon: Linkedin,
    title: "Audit gratuit de ta stratégie LinkedIn",
    description: "Identifie les leviers pour maximiser ton impact sur LinkedIn",
    features: [
      "Analyse de ton positionnement",
      "Audit de ta ligne éditoriale",
      "Optimisation de ton profil",
      "Stratégie de contenu personnalisée"
    ],
    href: "https://www.capygency.fr/audit-linkedin?utm_source=francoisbehague"
  }
];

export default function AuditsSection() {
  return (
    <div>
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold bg-gradient-primary text-transparent bg-clip-text inline-block">
          Audits gratuits
        </h2>
        <p className="mt-4 text-gray-600">
          Obtiens une analyse personnalisée de ta présence en ligne
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        {audits.map((audit, index) => (
          <div key={index} className="group relative transform hover:-translate-y-2 transition-all duration-300">
            <div className="absolute inset-0 bg-gradient-primary opacity-5 rounded-2xl transform rotate-1" />
            <div className="relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
              <div className="flex items-start space-x-4 mb-6">
                <div className="flex-shrink-0 w-16 h-16 rounded-xl bg-primary/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                  <audit.icon className="h-8 w-8 text-primary" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-secondary group-hover:text-primary transition-colors duration-300">
                    {audit.title}
                  </h3>
                  <p className="text-gray-600 mt-2">
                    {audit.description}
                  </p>
                </div>
              </div>

              <ul className="space-y-3 mb-8">
                {audit.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center space-x-3">
                    <span className="w-1.5 h-1.5 rounded-full bg-primary" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>

              <a
                href={audit.href}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 text-primary hover:text-white bg-primary/5 hover:bg-primary rounded-xl transition-all duration-300"
              >
                <span>Obtenir mon audit gratuit</span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}