import React from 'react';
import { Plus, Minus } from 'lucide-react';

const faqs = [
  {
    question: "J'ai déjà un bon profil...",
    answer: "L'optimisation n'est que 20% du succès. Ce qui fait la différence, c'est le système complet d'attraction de missions premium. Mes clients les mieux payés n'ont pas forcément les meilleurs profils, mais ils ont la meilleure stratégie."
  },
  {
    question: "Je n'ai pas le temps...",
    answer: "Le système est conçu pour les développeurs occupés. 2h/semaine suffisent pour maintenir le système en place. La plupart de mes clients continuent à coder pendant que les missions viennent à eux automatiquement."
  },
  {
    question: "Je suis junior...",
    answer: "40% de mes clients sont juniors et atteignent 500€/j en 2 mois. Le secret ? Un positionnement expert dans une micro-niche où ton niveau actuel suffit déjà à créer de la valeur."
  },
  {
    question: "Ça marche dans ma ville ?",
    answer: "Le système fonctionne partout en France et même à l'international. Nous avons des success stories à Paris, Lyon, Marseille, mais aussi dans des villes moyennes. Le travail à distance a effacé les barrières géographiques."
  },
  
   
];

export default function FreelanceFAQ() {
  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-highlight/5">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold bg-gradient-highlight text-transparent bg-clip-text inline-block mb-4">
            Questions Fréquentes
          </h2>
          <p className="text-xl text-gray-600">
            Les réponses à tes principales interrogations
          </p>
        </div>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <details
              key={index}
              className="group bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <summary className="flex items-center justify-between cursor-pointer p-6">
                <h3 className="text-lg font-semibold text-secondary pr-8 group-hover:text-highlight transition-colors duration-300">
                  {faq.question}
                </h3>
                <span className="flex-shrink-0">
                  <Plus className="h-5 w-5 text-highlight group-open:hidden" />
                  <Minus className="h-5 w-5 text-highlight hidden group-open:block" />
                </span>
              </summary>
              <div className="px-6 pb-6">
                <div className="prose prose-gray max-w-none">
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              </div>
            </details>
          ))}
        </div>

       
      </div>
    </div>
  );
}