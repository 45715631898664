import React from 'react';
import type { LucideIcon } from 'lucide-react';

interface GuaranteeFeature {
  text: string;
}

interface GuaranteeCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  features: GuaranteeFeature[];
}

export default function GuaranteeCard({ icon: Icon, title, description, features }: GuaranteeCardProps) {
  return (
    <div className="group relative transform hover:-translate-y-2 transition-all duration-300">
      <div className="absolute inset-0 bg-gradient-primary opacity-5 rounded-xl transform rotate-1" />
      <div className="relative bg-white/80 backdrop-blur rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
        {/* Icon Header */}
        <div className="flex items-center space-x-4 mb-6">
          <div className="flex-shrink-0 w-14 h-14 rounded-xl bg-gradient-to-br from-primary/10 to-primary/5 flex items-center justify-center text-primary group-hover:scale-110 transition-transform duration-300">
            <Icon className="h-7 w-7" />
          </div>
          <div>
            <h3 className="text-xl font-bold text-secondary group-hover:text-primary transition-colors duration-300">
              {title}
            </h3>
            <p className="text-gray-600 text-sm">{description}</p>
          </div>
        </div>

        {/* Features List */}
        <ul className="space-y-4">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start space-x-3">
              <span className="flex-shrink-0 w-5 h-5 rounded-full bg-primary/10 flex items-center justify-center mt-0.5">
                <span className="w-2 h-2 rounded-full bg-primary" />
              </span>
              <span className="text-gray-600">{feature.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}