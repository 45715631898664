import React from 'react';
import type { LucideIcon } from 'lucide-react';

interface MetricCardProps {
  icon: LucideIcon;
  value: string;
  label: string;
  subtitle?: string;
}

export default function MetricCard({ icon: Icon, value, label, subtitle }: MetricCardProps) {
  return (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      <div className="flex flex-col items-center">
        <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
          <Icon className="h-6 w-6 text-primary" />
        </div>
        <div className="text-2xl font-bold text-primary">{value}</div>
        <div className="text-gray-600">{label}</div>
        {subtitle && <div className="text-sm text-gray-500">{subtitle}</div>}
      </div>
    </div>
  );
}