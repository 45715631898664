import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { PortfolioTab } from '../types/portfolio';

export function usePortfolioTabs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get('tab') === 'development' ? 'development' : 'coaching';
  const [activeTab, setActiveTab] = useState<PortfolioTab>(defaultTab);

  const handleTabChange = (tab: PortfolioTab) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab === 'development' || tab === 'coaching') {
      setActiveTab(tab as PortfolioTab);
    }
  }, [searchParams]);

  return {
    activeTab,
    handleTabChange
  };
}