import React from 'react';
import { solutions } from './data/solutions';

export default function Solution() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Solution mise en place</h2>
      
      <div className="grid md:grid-cols-3 gap-8">
        {solutions.map((solution, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-lg">
            <div className="flex flex-col h-full">
              <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
                <solution.icon className="h-6 w-6 text-primary" />
              </div>
              
              <h3 className="text-xl font-semibold text-primary mb-4">{solution.title}</h3>
              
              <ul className="space-y-3">
                {solution.items.map((item, i) => (
                  <li key={i} className="flex items-start space-x-2">
                    <span className="w-1.5 h-1.5 bg-primary rounded-full mt-2" />
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-primary/5 rounded-xl p-6">
        <pre className="text-sm text-gray-800 overflow-x-auto">
          <code>{`// Exemple de service de traitement messages
type MessageProcessor struct {
    kafkaConsumer *kafka.Consumer
    mqttClient    mqtt.Client
    metrics       *Metrics
}

func (mp *MessageProcessor) ProcessMessages(ctx context.Context) error {
    // Logique de traitement optimisée
    // Buffer, batch processing, etc.
}`}</code>
        </pre>
      </div>
    </div>
  );
}