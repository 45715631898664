import type { Resource } from './types';

export const resources: Resource[] = [
  {
    title: "L'Ebook ultime Malt",
    description: "37 pages de stratégies concrètes pour devenir un Top Freelance sur Malt",
    features: [
      "Les secrets des profils qui convertissent",
      "La stratégie de positionnement premium",
      "Les scripts de réponse qui font la différence",
      "Le système d'attraction de missions automatique"
    ],
    pages: 37,
    format: "PDF",
    href: "https://e2f1-info.systeme.io/b17c304d"
  },
  {
    title: "444 idées de posts LinkedIn",
    description: "Un an de contenus pour développer ton personal branding et attirer des clients",
    features: [
      "444 prompts d'inspiration pour tes posts",
      "Catégories thématiques pour varier ton contenu",
      "Exemples de posts à fort engagement",
      "Calendrier éditorial sur 12 mois"
    ],
    pages: 50,
    format: "PDF",
    href: "#"
  }
];