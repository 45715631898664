import React from 'react';
import { Check, AlertTriangle } from 'lucide-react';

const successPoints = [
  "Approche itérative avec POC rapide",
  "Tests utilisateurs fréquents",
  "Documentation en continu"
];

const improvementPoints = [
  "Plus de tests unitaires",
  "Meilleure gestion des formats exotiques",
  "Optimisation plus tôt du cache"
];

export default function LessonsList() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Leçons apprises</h2>
      
      <div className="grid md:grid-cols-2 gap-8">
        <div className="bg-white rounded-xl p-6 shadow-lg">
          <h3 className="flex items-center text-xl font-semibold text-green-600 mb-4">
            <Check className="h-6 w-6 mr-2" />
            Ce qui a bien fonctionné
          </h3>
          
          <ul className="space-y-3">
            {successPoints.map((point, index) => (
              <li key={index} className="flex items-start space-x-2">
                <span className="w-1.5 h-1.5 bg-green-500 rounded-full mt-2" />
                <span className="text-gray-600">{point}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-lg">
          <h3 className="flex items-center text-xl font-semibold text-amber-600 mb-4">
            <AlertTriangle className="h-6 w-6 mr-2" />
            Points d'amélioration
          </h3>
          
          <ul className="space-y-3">
            {improvementPoints.map((point, index) => (
              <li key={index} className="flex items-start space-x-2">
                <span className="w-1.5 h-1.5 bg-amber-500 rounded-full mt-2" />
                <span className="text-gray-600">{point}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}