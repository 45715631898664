import React from 'react';
import { AlertCircle, XCircle } from 'lucide-react';

const problems = [
  "Tu passes tes soirées à prospecter au lieu de travailler",
  "Tes revenus font le yoyo malgré ton expertise",
  "Tu vois d'autres freelances croiser les bras pendant que tu galères",
  "Tu aimerais être reconnu comme expert mais tu restes invisible"
];

export default function FreelanceProblems() {
  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-accent/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold bg-gradient-danger from-highlight to-highlight-dark text-transparent bg-clip-text mb-4">                     En tant que freelance, c'est ton quotidien ?
          </h2>
          <div className="mt-8 text-xl text-red-600 font-semibold">
            78% des freelances sont coincés
          </div>
          <p className="mt-2 text-gray-600">
            Pendant que certains choisissent leurs missions à 750€/j
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Liste des problèmes */}
          <div className="space-y-6">
            {problems.map((problem, index) => (
              <div 
                key={index}
                className="group relative transform hover:-translate-y-1 transition-all duration-300"
              >
                <div className="absolute inset-0 bg-red-100 rounded-xl transform rotate-1 opacity-50" />
                <div className="relative bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-red-100">
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-10 h-10 rounded-xl bg-red-100 flex items-center justify-center text-red-500">
                      <XCircle className="h-6 w-6" />
                    </div>
                    <p className="text-red-700 text-lg">{problem}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Explication */}
          <div className="relative">
            <div className="sticky top-8">
              <div className="bg-white rounded-xl p-8 shadow-lg space-y-6">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-red-100 flex items-center justify-center text-red-500">
                    <AlertCircle className="h-7 w-7" />
                  </div>
                  <h3 className="text-2xl font-bold text-red-700">
                    Pourquoi tu galères vraiment
                  </h3>
                </div>

                <p className="text-gray-600 text-lg">
                  Ce n'est pas ton expertise technique qui bloque. Après avoir accompagné +125 freelances, 
                  j'ai découvert la vraie raison :
                </p>

                <div className="bg-red-50 rounded-xl p-6">
                  <h4 className="text-xl font-bold text-red-700 mb-3">
                    Le monde du freelancing a changé
                  </h4>
                  <p className="text-red-600">
                    La prospection active ne fonctionne plus. Les plateformes sont compliqués à exploiter. 
                    Les algorithmes pénalisent les débutants.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}