import React from 'react';
import ResourceCard from './ResourceCard';
import { resources } from '../../data/resources';

export default function ResourcesList() {
  return (
    <div className="grid md:grid-cols-2 gap-12">
      {resources.map((resource, index) => (
        <ResourceCard key={index} resource={resource} />
      ))}
    </div>
  );
}