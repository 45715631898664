import React from 'react';
import { AlertTriangle, XCircle } from 'lucide-react';

const signals = [
  {
    title: "Le Syndrome de l'Expertise Invisible",
    items: [
      "Tu es excellent techniquement",
      "Mais personne ne le remarque",
      "Pendant que des juniors décrochent des missions à 600€/j"
    ]
  },
  {
    title: "La Spirale de la Prospection",
    items: [
      "Tu passes plus de temps à chercher qu'à coder",
      "Chaque fin de mission est stressante",
      "Tu dois constamment baisser tes tarifs"
    ]
  },
  {
    title: "L'Épuisement du Freelance",
    items: [
      "Revenus en montagne russe",
      "Impossible de prévoir à 3 mois",
      "La peur de devoir retourner en salariat"
    ]
  }
];

export default function WarningSignals() {
  return (
    <div className="text-center">
      <div className="inline-flex items-center space-x-3 mb-12">
        <AlertTriangle className="h-8 w-8 text-danger" />
        <h3 className="text-2xl font-bold bg-gradient-danger text-transparent bg-clip-text">
          3 signaux d'alarme qui montrent que tu es coincé :
        </h3>
      </div>

      <div className="grid md:grid-cols-3 gap-8">
        {signals.map((signal, index) => (
          <div 
            key={index}
            className="group relative transform hover:-translate-y-2 transition-all duration-300"
          >
            <div className="absolute inset-0 bg-danger-100 rounded-xl transform rotate-1 opacity-70" />
            <div className="relative bg-white rounded-xl p-6 shadow-lg border border-danger">
              <div className="w-12 h-12 bg-gradient-danger rounded-full flex items-center justify-center text-white mx-auto mb-4">
                {index + 1}
              </div>
              <h4 className="text-lg font-bold text-danger mb-6">
                {signal.title}
              </h4>
              <ul className="space-y-4">
                {signal.items.map((item, i) => (
                  <li key={i} className="flex items-start space-x-3">
                    <XCircle className="h-5 w-5 text-danger flex-shrink-0 mt-0.5" />
                    <span className="text-gray-600 text-left">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}