import React from 'react';
import { Code, Users, ArrowRight } from 'lucide-react';
import { useUser } from "../../../contexts/UserContext";
import { useNavigate } from 'react-router-dom';

export default function UserJourneySelection() {
  const { setUserType } = useUser();
  const navigate = useNavigate();

  const handleSelection = (type: 'tech' | 'freelance') => {
    setUserType(type);
    navigate(`/contact/${type}`);
  };

  const journeys = [
    {
      icon: Code,
      title: "Un décideur tech cherchant un expert",
      description: "Tu as un projet technique à développer et cherches un expert pour le concrétiser",
      cta: "Démarre ton projet",
      type: "tech"
    },
    {
      icon: Users,
      title: "Un freelance voulant plus de missions",
      description: "Tu veux augmenter ta visibilité sur Malt et décrocher plus de missions premium",
      cta: "Réserve ton call stratégique",
      type: "freelance"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-primary/5 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text inline-block mb-4">
            Je suis...
          </h1>
          <p className="text-xl text-gray-600">
            Choisis ton parcours et découvre les solutions adaptées à tes besoins
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {journeys.map((journey, index) => (
            <button
              key={index}
              onClick={() => handleSelection(journey.type as 'tech' | 'freelance')}
              className={`group relative overflow-hidden bg-gradient-to-br ${
                journey.type === 'tech' 
                  ? 'from-primary/5 hover:from-primary/10' 
                  : 'from-highlight/5 hover:from-highlight/10'
              } to-transparent p-8 rounded-2xl transition-all duration-300 transform hover:-translate-y-1 text-left`}
            >
              {/* Background pattern */}
              <div className="absolute inset-0 bg-grid-primary opacity-10" />

              <div className="relative">
                {/* Icon */}
                <div className={`inline-flex items-center justify-center w-16 h-16 rounded-xl ${
                  journey.type === 'tech'
                    ? 'bg-primary/10 text-primary group-hover:bg-primary/20'
                    : 'bg-highlight/10 text-highlight group-hover:bg-highlight/20'
                } mb-6 transition-colors duration-300`}>
                  <journey.icon className="h-8 w-8" />
                </div>

                {/* Content */}
                <h3 className={`text-2xl font-bold mb-4 ${
                  journey.type === 'tech' ? 'text-primary' : 'text-highlight'
                }`}>
                  {journey.title}
                </h3>
                <p className="text-gray-600 mb-8">
                  {journey.description}
                </p>

                {/* CTA */}
                <div className={`inline-flex items-center ${
                  journey.type === 'tech' 
                    ? 'text-primary group-hover:text-primary-dark' 
                    : 'text-highlight group-hover:text-highlight-dark'
                }`}>
                  <span className="font-medium">{journey.cta}</span>
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}