import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import CaseStudyHeader from '../../components/portfolio/case-studies/react-portfolio/CaseStudyHeader';
import InitialContext from '../../components/portfolio/case-studies/react-portfolio/InitialContext';
import Solution from '../../components/portfolio/case-studies/react-portfolio/Solution';
import Results from '../../components/portfolio/case-studies/react-portfolio/Results';
import DevelopmentProcess from '../../components/portfolio/case-studies/react-portfolio/process/DevelopmentProcess';
import LessonsList from '../../components/portfolio/case-studies/react-portfolio/lessons/LessonsList';
import Conclusion from '../../components/portfolio/case-studies/react-portfolio/Conclusion';

export default function ReactPortfolio() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-primary/5 py-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 bg-grid-primary opacity-10" />
        
        <div className="relative">
          <Link
            to="/portfolio"
            className="inline-flex items-center text-primary hover:text-primary-dark transition-colors duration-300 mb-8"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Retour aux études de cas
          </Link>

          <div className="space-y-16">
            <CaseStudyHeader />
            <InitialContext />
            <Solution />
            <Results />
            <DevelopmentProcess />
            <LessonsList />
            <Conclusion />
          </div>
        </div>
      </div>
    </div>
  );
}