import React from 'react';
import MetricCard from './MetricCard';
import type { Metric } from '../types';

interface MetricsGridProps {
  metrics: Metric[];
  columns?: 3 | 4;
}

export default function MetricsGrid({ metrics, columns = 3 }: MetricsGridProps) {
  return (
    <div className={`grid md:grid-cols-${columns} gap-8`}>
      {metrics.map((metric, index) => (
        <MetricCard key={index} {...metric} />
      ))}
    </div>
  );
}