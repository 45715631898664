import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const bluefrogCaseStudy: CaseStudy = {
  id: 8,
  client: "Migration Webflow",
  before: "Sites lents et peu performants",
  after: "Score PageSpeed > 90, temps de chargement -60%",
  duration: "3 mois",
  testimonial: "La migration vers Webflow et l'optimisation des performances ont transformé l'expérience de nos utilisateurs. Les temps de chargement réduits ont eu un impact direct sur nos conversions.",
  image: IMAGES.caseStudies.development.bluefrog,
  success: "Performance x2.5 !"
};