export const IMAGES = {
  caseStudies: {
    coaching: {
      nicolas: '/images/case-studies/nicolas_facon.jpeg',
      sophie: '/images/case-studies/sophie_potier.jpeg',
      audran: '/images/case-studies/audran_campoy.jpeg',
      ronan: '/images/case-studies/ronan_jaffré.png'
    },
    development: {
      iot: 'https://images.unsplash.com/photo-1558346490-a72e53ae2d4f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      pdf: 'https://images.unsplash.com/photo-1568025130544-f8c8d9c8f76f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      cv: 'https://images.unsplash.com/photo-1586281380349-632531db7ed4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      nycticorax: 'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&auto=format&fit=crop&w=2090&q=80',
      bluefrog: 'https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2090&q=80'
    }
  },
  hero: '/images/hero-background.jpg'
};