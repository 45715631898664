import { bluefrogCaseStudy } from './bluefrog-sites';
import { nycticoraxCaseStudy } from './nycticorax-sites';
import { cvAnalysisCaseStudy } from './cv-analysis';
import { iotAppCaseStudy } from './iot-app';
import { pdfPlatformCaseStudy } from './pdf-platform';
import type { CaseStudy } from '../../types';

// Organiser les études de cas par date (les plus récentes en premier)
export const developmentCaseStudies: CaseStudy[] = [
  bluefrogCaseStudy,      // 2024
  nycticoraxCaseStudy,    // 2021
  cvAnalysisCaseStudy,    // 2023
  iotAppCaseStudy,        // 2023
  pdfPlatformCaseStudy    // 2023
];