import React from 'react';
import CaseStudyCard from '../cards/CaseStudyCard';
import type { CaseStudy } from '../../../types/caseStudy';
import EmptyState from '../../common/EmptyState';

interface CaseStudyGridProps {
  studies: CaseStudy[];
  type: 'coaching' | 'development';
}

export default function CaseStudyGrid({ studies, type }: CaseStudyGridProps) {
  if (!studies?.length) {
    return <EmptyState message="Aucune étude de cas disponible" />;
  }

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      {studies.map((study) => (
        <CaseStudyCard key={study.id} study={study} type={type} />
      ))}
    </div>
  );
}