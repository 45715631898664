import React from 'react';
import { Target, Shield, TrendingUp } from 'lucide-react';
import BenefitCard from './BenefitCard';

const benefits = [
  {
    icon: Target,
    title: "Clarté totale",
    description: "Un tableau de bord qui te montre l'avancement en temps réel"
  },
  {
    icon: Shield,
    title: "Zéro surprise",
    description: "Budget fixe garanti par contrat, planning détaillé dès le début"
  },
  {
    icon: TrendingUp,
    title: "Focus business",
    description: "Chaque ligne de code est écrite avec tes objectifs business en tête"
  }
];

export default function BetterApproach() {
  return (
    <div className="relative">
      <div className="text-center">
        <h3 className="text-3xl font-bold bg-gradient-primary text-transparent bg-clip-text inline-block mb-4">
          Mais il existe une meilleure approche
        </h3>
        <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
          En tant qu'entrepreneur moi-même, j'ai développé une méthode qui allie 
          <span className="text-primary font-semibold"> expertise technique</span> et
          <span className="text-primary font-semibold"> vision business</span>.
        </p>
        
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-radial from-primary/5 to-transparent opacity-50" />
          <div className="relative bg-white/80 backdrop-blur rounded-2xl p-8 shadow-lg">
            <h4 className="text-2xl font-bold text-secondary mb-10">
              Comment je transforme des projets voués à l'échec en succès rentables :
            </h4>
            
            <div className="grid md:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <BenefitCard key={index} {...benefit} />
              ))}
            </div>
          </div>
        </div>

        <div className="mt-12 inline-block transform hover:-translate-y-1 transition-all duration-300">
          <div className="relative">
            <div className="absolute inset-0 bg-green-100 rounded-xl transform rotate-1 opacity-50" />
            <div className="relative bg-gradient-to-r from-green-50 to-white p-6 rounded-xl shadow-lg border border-green-100">
              <p className="text-xl text-green-700 font-bold flex items-center justify-center">
                <span className="text-3xl mr-2">🎯</span>
                La preuve ? Plus de 15 projets livrés avec 100% de satisfaction client.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}