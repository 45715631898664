import type { Experience } from '../types';

export const axeCapitalExperience: Experience = {
  title: "Développeur d'applications web full-stack / Javascript PHP",
  company: "Axe Capital",
  period: "Mai 2023 - Juillet 2023",
  location: "Lyon, France · À distance",
  description: [
    "Conception et développement d'une solution innovante d'extraction et d'analyse de CV via intelligence artificielle, transformant des PDF bruts en données exploitables pour le recrutement."
  ],
  achievements: [
    "Précision d'extraction > 95%",
    "Temps d'analyse réduit de 30min à 2min/CV",
    "Création d'un algorithme d'extraction intelligent multi-format",
    "Développement d'une interface intuitive de gestion des données",
    "Mise en place d'un système de normalisation automatique",
    "Intégration d'OpenAI pour l'analyse sémantique avancée"
  ]
};