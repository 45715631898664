import React from 'react';
import FreelanceHero from '../hero/FreelanceHero';
import FreelanceProblems from './FreelanceProblems';
import FreelanceAgitation from './FreelanceAgitation';
import FreelanceSystem from './FreelanceSystem';
import FreelanceTransformation from './FreelanceTransformation';
import FreelanceFAQ from './FreelanceFAQ';
import FreelanceContact from '../../contact/FreelanceContact';

export default function FreelanceLanding() {
  return (
    <div className="min-h-screen bg-white">
      <FreelanceHero />
      <FreelanceProblems />
      <FreelanceAgitation />
      <FreelanceSystem />
      <FreelanceTransformation />
      <FreelanceFAQ />
      <FreelanceContact />
    </div>
  );
}