import React from 'react';
import { experiences } from '../../data/experiences';
import ExperienceCard from './ExperienceCard';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function ExperienceSection() {
  return (
    <div className="relative bg-gradient-to-br from-secondary/5 via-white to-primary/5 py-12">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-grid-primary opacity-10" />
      
      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text inline-block">
            Expériences professionnelles
          </h1>
          <p className="mt-4 text-xl text-gray-600">
            Mon parcours 
          </p>
        </div>

        <div className="relative pl-8 space-y-8">
          {experiences.map((experience, index) => (
            <ExperienceCard key={index} experience={experience} index={index} />
          ))}
        </div>

        {/* CTA Section */}
        <div className="mt-16 text-center">
          <Link
            to="/contact"
            className="inline-flex items-center px-8 py-4 bg-gradient-primary text-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
          >
            <span className="text-lg font-semibold">Discuter de votre projet</span>
            <ArrowRight className="ml-3 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
}