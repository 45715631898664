import React from 'react';
import { XCircle, CheckCircle, Trophy } from 'lucide-react';

interface CaseStudyResultsProps {
  before: string;
  after: string;
  title: string;
}

export default function CaseStudyResults({ before, after, title }: CaseStudyResultsProps) {
  return (
    
    <div className="bg-white/80 backdrop-blur rounded-xl shadow-lg p-8 space-y-6">
      <div className="space-y-6">
        
        {/* Badge de succès */}
        <div className="flex items-center justify-center">
          <div className="bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-white px-6 py-3 rounded-full shadow-lg flex items-center space-x-3 transform hover:scale-105 transition-transform duration-300">
            <Trophy className="h-5 w-5" />
            <span className="font-semibold">{title}</span>
          </div>
        </div>

        
        {/* Situation initiale */}
        <div className="status-before flex items-start space-x-4">
          <XCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
          <div>
            <h3 className="text-xl font-semibold text-red-700 mb-2">Situation initiale</h3>
            <p className="text-red-600">{before}</p>
          </div>
        </div>

        {/* Résultats */}
        <div className="status-after flex items-start space-x-4">
          <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
          <div>
            <h3 className="text-xl font-semibold text-green-700 mb-2">Résultats obtenus</h3>
            <p className="text-green-600">{after}</p>
          </div>
        </div>
      </div>
    </div>
  );
}