import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const pdfPlatformCaseStudy: CaseStudy = {
  id: 5,
  client: "Plateforme d'édition PDF",
  before: "Solution complexe et peu intuitive",
  after: "Interface fluide, compatible 98% navigateurs",
  duration: "2 mois",
  testimonial: "La nouvelle interface est un vrai plus pour nos utilisateurs. La compatibilité cross-browser nous a permis d'élargir notre base clients.",
  image: IMAGES.caseStudies.development.pdf,
  success: "UX optimisée à 98% !"
};