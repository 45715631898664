import { Code, Users } from 'lucide-react';
import type { Journey } from './types';

export const journeyOptions: Journey[] = [
  {
    icon: Code,
    title: "Un projet tech à développer ?",
    description: "Je transforme tes idées en applications performantes avec une vision business et des résultats garantis",
    features: [
      "Architecture robuste et scalable",
      "Code de qualité production",
      "Budget et délais garantis",
      "Support et maintenance inclus"
    ],
    cta: "Découvrir les solutions",
    type: "tech"
  },
  {
    icon: Users,
    title: "Freelance en quête de missions ?",
    description: "Créé ton système d'acquisition de missions automatique et décroche des missions premium sans prospection",
    features: [
      "Positionnement expert unique",
      "Profil qui convertit naturellement",
      "Pipeline de missions constant",
      "Accompagnement personnalisé"
    ],
    cta: "Booster ton activité",
    type: "freelance"
  }
];