import React from 'react';
import ProcessPhase from './ProcessPhase';

const phases = [
  {
    title: "POC",
    duration: "2 semaines",
    tasks: [
      "Preuve de concept extraction",
      "Tests avec OpenAI",
      "Validation approche"
    ]
  },
  {
    title: "MVP",
    duration: "1 mois",
    tasks: [
      "API REST FastAPI",
      "Interface utilisateur",
      "Stockage MongoDB"
    ]
  },
  {
    title: "Optimisation",
    duration: "3 semaines",
    tasks: [
      "Cache Redis",
      "Batch processing",
      "Monitoring"
    ]
  },
  {
    title: "Production",
    duration: "2 semaines",
    tasks: [
      "Tests de charge",
      "Documentation",
      "Formation équipe"
    ]
  }
];

export default function DevelopmentProcess() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Processus de développement</h2>
      
      <div className="space-y-8">
        {phases.map((phase, index) => (
          <ProcessPhase key={index} phase={phase} index={index} />
        ))}
      </div>
    </div>
  );
}