import React from 'react';
import { Code, Users, Sparkles, Star } from 'lucide-react';

export default function BackgroundDecoration() {
  return (
    <>
      {/* Main gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-white via-gray-50 to-primary/5" />
      
      {/* Grid pattern */}
      <div className="absolute inset-0 bg-grid-primary opacity-10" />
      
      {/* Floating icons */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Top left group */}
        <div className="absolute -top-4 -left-4 w-32 h-32 bg-primary/5 rounded-full blur-3xl animate-pulse" />
        <div className="absolute top-12 left-8 transform -rotate-12">
          <div className="w-10 h-10 bg-primary/10 rounded-lg flex items-center justify-center">
            <Code className="h-5 w-5 text-primary animate-bounce" />
          </div>
        </div>

        {/* Top right group */}
        <div className="absolute -top-8 -right-8 w-40 h-40 bg-highlight/5 rounded-full blur-3xl animate-pulse delay-1000" />
        <div className="absolute top-16 right-12 transform rotate-12">
          <div className="w-10 h-10 bg-highlight/10 rounded-lg flex items-center justify-center">
            <Users className="h-5 w-5 text-highlight animate-bounce delay-1000" />
          </div>
        </div>

        {/* Bottom decorations */}
        <div className="absolute -bottom-16 left-1/4 w-48 h-48 bg-primary/5 rounded-full blur-3xl animate-pulse delay-500" />
        <div className="absolute -bottom-8 right-1/4 w-32 h-32 bg-highlight/5 rounded-full blur-3xl animate-pulse delay-700" />
        
        {/* Additional floating elements */}
        <div className="absolute top-1/4 left-1/3">
          <Star className="h-4 w-4 text-primary/20 animate-ping" />
        </div>
        <div className="absolute bottom-1/3 right-1/3">
          <Sparkles className="h-4 w-4 text-highlight/20 animate-ping delay-500" />
        </div>
      </div>

      {/* Radial gradient overlay */}
      <div className="absolute inset-0 bg-gradient-radial from-transparent via-white/50 to-white pointer-events-none" />
    </>
  );
}