import React from 'react';
import { Code, Clock, Target } from 'lucide-react';

export default function CaseStudyHeader() {
  const metrics = [
    {
      icon: Code,
      value: "4 sites",
      label: "livrés en parallèle"
    },
    {
      icon: Clock,
      value: "4 mois",
      label: "au lieu de 6 prévus"
    },
    {
      icon: Target,
      value: "60%",
      label: "de code réutilisé"
    }
  ];

  return (
    <div className="text-center space-y-8">
      <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text">
        Portfolio de sites React
      </h1>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        D'une base de code fragmentée à une architecture réutilisable
      </p>

      <div className="grid md:grid-cols-3 gap-8">
        {metrics.map((metric, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-lg">
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
                <metric.icon className="h-6 w-6 text-primary" />
              </div>
              <div className="text-2xl font-bold text-primary">{metric.value}</div>
              <div className="text-gray-600">{metric.label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}