import React from 'react';
import { Target, Linkedin, Users, Code, Database, Cpu, Gauge, Shield, Sparkles, TrendingUp, MessageSquare } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface MethodologyStep {
  icon: LucideIcon;
  title: string;
  description: string;
}

interface CaseStudyMethodologyProps {
  isNicolasCase: boolean;
  type?: 'coaching' | 'development';
  projectId?: number;
}

export default function CaseStudyMethodology({ isNicolasCase, type, projectId }: CaseStudyMethodologyProps) {
  const getMethodology = (): MethodologyStep[] => {
    if (type === 'coaching') {
      if (isNicolasCase) {
        return [
          {
            icon: Target,
            title: "Analyse initiale",
            description: "Évaluation approfondie du profil et identification des points d'amélioration clés."
          },
          {
            icon: Sparkles,
            title: "Optimisation du profil Malt",
            description: "Restructuration complète du profil pour maximiser la visibilité et l'attractivité."
          },
          {
            icon: Linkedin,
            title: "Optimisation du profil LinkedIn",
            description: "Harmonisation de la présence professionnelle sur les réseaux."
          },
          {
            icon: Users,
            title: "Amélioration du parcours client",
            description: "Optimisation de l'expérience client de la première prise de contact à la signature."
          },
          {
            icon: MessageSquare,
            title: "Amélioration de la vente et négociation",
            description: "Techniques de communication efficaces pour convertir les opportunités en missions."
          }
        ];
      }
      return [
        {
          icon: Target,
          title: "Analyse initiale",
          description: "Évaluation approfondie du profil et identification des points d'amélioration clés."
        },
        {
          icon: Users,
          title: "Positionnement stratégique",
          description: "Définition d'une niche spécifique et d'un positionnement unique sur le marché."
        },
        {
          icon: Sparkles,
          title: "Optimisation des profils",
          description: "Restructuration complète des profils professionnels pour maximiser la visibilité."
        }
      ];
    }

    if (type === 'development') {
      switch (projectId) {
        case 4: // IoT
          return [
            {
              icon: Database,
              title: "Architecture IoT",
              description: "Conception d'une architecture scalable et performante pour la gestion des données IoT."
            },
            {
              icon: Gauge,
              title: "Optimisation",
              description: "Mise en place de solutions pour réduire les coûts et améliorer les performances."
            },
            {
              icon: Cpu,
              title: "Microservices",
              description: "Implémentation d'une architecture microservices pour une meilleure scalabilité."
            }
          ];
        case 5: // PDF Platform
          return [
            {
              icon: Shield,
              title: "Sécurité",
              description: "Implémentation de mesures de sécurité robustes pour la protection des données."
            },
            {
              icon: Code,
              title: "Développement",
              description: "Création d'une interface utilisateur intuitive et compatible multi-navigateurs."
            },
            {
              icon: Gauge,
              title: "Performance",
              description: "Optimisation des performances pour une expérience utilisateur fluide."
            }
          ];
        case 6: // CV Analysis
          return [
            {
              icon: Database,
              title: "Extraction de données",
              description: "Développement d'algorithmes d'extraction intelligents pour l'analyse des CV."
            },
            {
              icon: Cpu,
              title: "IA & Automatisation",
              description: "Intégration d'OpenAI pour l'analyse sémantique et l'automatisation du processus."
            },
            {
              icon: Gauge,
              title: "Optimisation",
              description: "Amélioration continue des performances et de la précision du système."
            }
          ];
        default:
          return [];
      }
    }

    return [];
  };

  const methodology = getMethodology();

  return (
    <div className="bg-white/80 backdrop-blur rounded-xl shadow-lg p-8">
      <h2 className="text-2xl font-bold bg-gradient-primary text-transparent bg-clip-text mb-6">
        Notre approche
      </h2>
      <div className="space-y-6">
        <div className="space-y-8">
          {methodology.map((method, index) => (
            <div key={index} className="flex items-start space-x-4 group hover:bg-primary/5 p-4 rounded-lg transition-colors duration-300">
              <div className="bg-primary/10 p-3 rounded-lg group-hover:bg-primary/20 transition-colors duration-300">
                <method.icon className="h-6 w-6 text-primary" />
              </div>
              <div>
                <h3 className="font-semibold text-secondary mb-2 group-hover:text-primary transition-colors duration-300">
                  {method.title}
                </h3>
                <p className="text-gray-600 group-hover:text-gray-700 transition-colors duration-300">
                  {method.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}