import React from 'react';
import { CheckCircle, TrendingUp, Users } from 'lucide-react';

interface ResultCardProps {
  month: string;
  title: string;
  results: string[];
  highlight: string;
  index: number;
  stats: {
    views: string;
    leads: string;
    label: string;
  };
}

export default function ResultCard({ month, title, results, highlight, index, stats }: ResultCardProps) {
  return (
    <div className="group relative transform hover:-translate-y-2 transition-all duration-300">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-gradient-highlight opacity-5 rounded-xl transform rotate-1" />
      
      <div className="relative bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-highlight/10">
        {/* Month badge */}
        <div className="absolute -top-4 left-8 bg-gradient-to-r from-highlight to-highlight-dark text-white px-4 py-1 rounded-full text-sm font-bold shadow-lg">
          Mois {index}
        </div>

        {/* Header */}
        <div className="text-center mb-8 pt-4">
          <div className="w-16 h-16 bg-gradient-highlight rounded-full flex items-center justify-center text-white font-bold mx-auto mb-4 group-hover:scale-110 transition-transform duration-300 text-xl">
            {title[0]}
          </div>
          <h4 className="text-xl font-bold text-highlight">
            {title}
          </h4>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-2 gap-4 mb-8">
          <div className="text-center p-3 bg-highlight/5 rounded-lg">
            <div className="flex items-center justify-center space-x-2 text-highlight mb-1">
              <TrendingUp className="h-4 w-4" />
              <span className="font-bold">{stats.views}</span>
            </div>
            <p className="text-xs text-gray-600">{stats.label}</p>
          </div>
          <div className="text-center p-3 bg-highlight/5 rounded-lg">
            <div className="flex items-center justify-center space-x-2 text-highlight mb-1">
              <Users className="h-4 w-4" />
              <span className="font-bold">{stats.leads}</span>
            </div>
            <p className="text-xs text-gray-600">leads/semaine</p>
          </div>
        </div>

        {/* Results */}
        <ul className="space-y-4 mb-6">
          {results.map((result, index) => (
            <li key={index} className="flex items-start space-x-3">
              <CheckCircle className="h-5 w-5 text-highlight flex-shrink-0 mt-0.5" />
              <span className="text-gray-600">{result}</span>
            </li>
          ))}
        </ul>

        {/* Highlight */}
        <div className="mt-auto">
          <div className="bg-gradient-to-r from-highlight to-highlight-dark text-white p-3 rounded-lg text-center font-semibold">
            {highlight}
          </div>
        </div>

        {/* Hover effect overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-highlight/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl pointer-events-none" />
      </div>
    </div>
  );
}