import React from 'react';
import IntroSection from './better-approach/IntroSection';
import WarningSignals from './better-approach/WarningSignals';
import BetterApproach from './better-approach/BetterApproach';

export default function TechBetterApproach() {
  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-primary/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="space-y-16">
          <IntroSection />
          <WarningSignals />
          <BetterApproach />
        </div>
      </div>
    </div>
  );
}