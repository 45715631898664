import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, XCircle, CheckCircle } from 'lucide-react';
import type { CaseStudy } from '../../../types/caseStudy';

interface CaseStudyCardProps {
  study: CaseStudy;
  type: 'coaching' | 'development';
}

export default function CaseStudyCard({ study, type }: CaseStudyCardProps) {
  const getStudyUrl = () => {
    switch (study.id) {
      case 7:
        return '/portfolio/development/react-portfolio';
      case 4:
        return '/portfolio/development/iot-architecture';
      default:
        return `/portfolio/${type}/${study.id}`;
    }
  };

  return (
    <Link 
      to={getStudyUrl()}
      className="group bg-white/80 backdrop-blur rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 hover:border-primary/20 overflow-hidden flex flex-col h-full transform hover:-translate-y-1"
    >
      <div className="relative mx-auto w-3/4 pt-[50%] mt-6">
        <div className="absolute inset-0">
          <img
            src={study.image}
            alt={study.client}
            className="absolute inset-0 w-full h-full object-cover object-center rounded-xl group-hover:scale-105 transition-transform duration-500"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-secondary/90 via-secondary/40 to-transparent rounded-xl" />
        </div>
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <h3 className="text-xl font-bold text-white text-center">{study.client.split('-')[0]}</h3>
        </div>
      </div>

      <div className="p-6 flex-grow flex flex-col">
        <div className="space-y-6 flex-grow">
          <div className="bg-red-50 rounded-lg p-4 border border-red-100">
            <div className="flex items-start space-x-3">
              <XCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
              <div>
                <h4 className="font-semibold text-red-700 mb-2">Situation initiale</h4>
                <p className="text-red-600 text-lg">{study.before}</p>
              </div>
            </div>
          </div>

          <div className="bg-green-50 rounded-lg p-4 border border-green-100">
            <div className="flex items-start space-x-3">
              <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
              <div>
                <h4 className="font-semibold text-green-700 mb-2">Résultats obtenus</h4>
                <p className="text-green-600 text-lg">{study.after}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-6 mt-6 border-t border-gray-100">
          <div className="flex items-center justify-between text-primary font-medium group-hover:translate-x-2 transition-transform duration-300">
            <span>Voir l'étude complète</span>
            <ArrowRight className="h-5 w-5" />
          </div>
        </div>
      </div>
    </Link>
  );
}