import React from 'react';
import { TrendingUp, Clock, Brain, Star } from 'lucide-react';

const metrics = [
  {
    icon: Clock,
    title: "Temps de traitement",
    value: "2min",
    subtitle: "vs 30min avant"
  },
  {
    icon: Brain,
    title: "Précision",
    value: "95%",
    subtitle: "d'extraction correcte"
  },
  {
    icon: TrendingUp,
    title: "Productivité",
    value: "x15",
    subtitle: "plus de CV traités"
  },
  {
    icon: Star,
    title: "Satisfaction",
    value: "98%",
    subtitle: "équipe RH"
  }
];

const improvements = [
  {
    label: "Temps par CV",
    before: "30min",
    after: "2min"
  },
  {
    label: "CV traités/jour",
    before: "15",
    after: "225"
  },
  {
    label: "Taux d'erreur",
    before: "15%",
    after: "5%"
  }
];

export default function Results() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Résultats obtenus</h2>

      <div className="grid md:grid-cols-4 gap-6">
        {metrics.map((metric, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-lg">
            <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
              <metric.icon className="h-6 w-6 text-primary" />
            </div>
            <div className="text-2xl font-bold text-primary">{metric.value}</div>
            <div className="text-sm text-gray-600">{metric.title}</div>
            <div className="text-xs text-gray-500">{metric.subtitle}</div>
          </div>
        ))}
      </div>

      <div className="bg-white rounded-xl p-8 shadow-lg">
        <h3 className="text-xl font-semibold text-primary mb-6">Améliorations techniques</h3>
        <div className="space-y-4">
          {improvements.map((improvement, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="w-1/3 text-gray-600">{improvement.label}</div>
              <div className="flex-1 flex items-center space-x-4">
                <div className="bg-red-100 px-3 py-1 rounded-full text-red-700">
                  {improvement.before}
                </div>
                <div className="text-gray-400">→</div>
                <div className="bg-green-100 px-3 py-1 rounded-full text-green-700">
                  {improvement.after}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}