import React from 'react';
import { AlertTriangle, TrendingDown, BarChart } from 'lucide-react';

const challenges = [
  {
    title: "Projets qui prennent du retard",
    description: "Comme ce client qui avait perdu 6 mois avec une équipe précédente, sans visibilité sur l'avancement."
  },
  {
    title: "Dépassements de budget imprévus",
    description: "Une startup qui a vu sa facture doubler à cause de 'coûts cachés' et de 'fonctionnalités additionnelles'."
  },
  {
    title: "Communication difficile",
    description: "Un projet bloqué pendant des semaines à cause d'une équipe offshore peu réactive et d'incompréhensions."
  },
  {
    title: "Code de mauvaise qualité",
    description: "Une application instable nécessitant une réécriture complète à cause d'un code non maintenu."
  }
];

export default function TechChallenges() {
  return (
    <div className="py-16 bg-gradient-to-br from-white via-gray-50 to-primary/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
<h2 className="text-4xl font-extrabold bg-gradient-primary text-transparent bg-clip-text inline-block mb-4">
            Tu es entrepreneur ou dirigeant et...
          </h2>
          <p className="text-xl text-gray-600">
            Tu as déjà vécu ces situations frustrantes ?
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Liste des défis */}
          <div className="space-y-6">
            {challenges.map((challenge, index) => (
              <div key={index} className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 rounded-xl flex items-center justify-center bg-red-50 text-red-500">
                    <AlertTriangle className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-red-700 mb-2">
                      {challenge.title}
                    </h3>
                    <p className="text-gray-600">
                      {challenge.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Statistiques et cas client */}
          <div className="space-y-8">
            {/* Statistique */}
            <div className="bg-white rounded-xl p-8 shadow-lg">
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-3 bg-primary/10 rounded-lg">
                  <BarChart className="h-8 w-8 text-primary" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-secondary">68%</h3>
                  <p className="text-gray-600">des projets tech échouent</p>
                </div>
              </div>
              <p className="text-gray-600">
                La plupart des entrepreneurs pensent qu'il suffit de trouver un "bon développeur" pour réussir. 
                La réalité ? 7 projets sur 10 dérapent à cause d'une seule erreur critique : confondre code et création de valeur business.
              </p>
            </div>

            {/* Cas client */}
            <div className="bg-white rounded-xl p-8 shadow-lg">
              <div className="flex items-start space-x-4">
                <div className="p-3 bg-green-100 rounded-lg">
                  <TrendingDown className="h-8 w-8 text-green-600" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-secondary mb-2">
                    L'Histoire de Thomas, Fondateur de PayFlex
                  </h3>
                  <p className="text-gray-600 mb-4">
                    "J'avais déjà investi 40K€ dans une équipe offshore. 6 mois plus tard, j'avais un code inutilisable et plus un euro en banque. J'étais au bord du burnout..."
                  </p>
                  <p className="text-green-600 font-semibold">
                    Aujourd'hui ? PayFlex traite 2M€ de transactions par mois. La différence ? Une approche orientée business, pas juste technique.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}