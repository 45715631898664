import React from 'react';
import { Linkedin, Users, Code, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

export default function Services() {
  const navigate = useNavigate();
  const { setUserType } = useUser();

  const handleTechClick = () => {
    setUserType('tech');
    navigate('/');
  };

  const services = [
    {
      title: "Deviens un expert LinkedIn",
      description: "Découvre comment multiplier tes opportunités professionnelles grâce à un profil LinkedIn optimisé",
      icon: Linkedin,
      cta: "Je booste mon LinkedIn",
      href: "https://www.capygency.fr",
      type: "primary"
    },
    {
      title: "Deviens top freelance sur Malt",
      description: "Apprends à te positionner parmi les meilleurs et à décrocher des missions premium",
      icon: Users,
      cta: "Je deviens Supermalter",
      href: "https://www.capygency.fr",
      type: "highlight"
    },
    {
      title: "Un projet tech à développer ?",
      description: "Discutons de ton projet et voyons comment le concrétiser ensemble",
      icon: Code,
      cta: "On en discute",
      onClick: handleTechClick,
      type: "primary"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-primary/5 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text inline-block">
            Trouve la solution qui te correspond
          </h1>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div 
              key={index}
              className={`bg-gradient-to-br ${
                service.type === 'highlight' 
                  ? 'from-highlight/5' 
                  : 'from-primary/5'
              } to-transparent p-8 rounded-2xl`}
            >
              <div className="flex flex-col h-full">
                <div className={`inline-flex items-center justify-center w-12 h-12 rounded-xl ${
                  service.type === 'highlight'
                    ? 'bg-highlight/10 text-highlight'
                    : 'bg-primary/10 text-primary'
                } mb-6`}>
                  <service.icon className="h-6 w-6" />
                </div>

                <h3 className="text-2xl font-bold text-secondary mb-4">
                  {service.title}
                </h3>

                <p className="text-gray-600 mb-8 flex-grow">
                  {service.description}
                </p>

                {service.onClick ? (
                  <button
                    onClick={service.onClick}
                    className={`group inline-flex items-center px-6 py-3 rounded-xl transition-all duration-300 ${
                      service.type === 'highlight'
                        ? 'text-highlight hover:text-white bg-highlight/5 hover:bg-highlight'
                        : 'text-primary hover:text-white bg-primary/5 hover:bg-primary'
                    }`}
                  >
                    <span>{service.cta}</span>
                    <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
                  </button>
                ) : (
                  <a
                    href={service.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`group inline-flex items-center px-6 py-3 rounded-xl transition-all duration-300 ${
                      service.type === 'highlight'
                        ? 'text-highlight hover:text-white bg-highlight/5 hover:bg-highlight'
                        : 'text-primary hover:text-white bg-primary/5 hover:bg-primary'
                    }`}
                  >
                    <span>{service.cta}</span>
                    <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}