import React from 'react';
import type { LucideIcon } from 'lucide-react';

interface PhaseCardProps {
  icon: LucideIcon;
  title: string;
  period: string;
  items: string[];
  isRight: boolean;
}

export default function PhaseCard({ icon: Icon, title, period, items, isRight }: PhaseCardProps) {
  return (
    <div className="lg:w-1/2 w-full">
      <div className="group relative transform hover:-translate-y-2 transition-all duration-300">
        <div className="absolute inset-0 bg-gradient-highlight opacity-5 rounded-xl transform rotate-1" />
        <div className="relative bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-highlight/10">
          {/* Timeline dot */}
          <div className="absolute top-1/2 -translate-y-1/2 w-6 h-6 rounded-full bg-gradient-highlight border-4 border-white shadow-md hidden lg:block"
            style={{ 
              [isRight ? 'right' : 'left']: '-3rem',
            }}
          />
          
          {/* Header */}
          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0 w-14 h-14 rounded-xl bg-highlight/10 flex items-center justify-center text-highlight group-hover:scale-110 transition-transform duration-300">
              <Icon className="h-7 w-7" />
            </div>
            <div>
              <h3 className="text-xl font-bold text-secondary group-hover:text-highlight transition-colors duration-300">
                {title}
              </h3>
              <p className="text-gray-600">{period}</p>
            </div>
          </div>

          {/* Items */}
          <ul className="space-y-4">
            {items.map((item, index) => (
              <li key={index} className="flex items-start space-x-3">
                <span className="flex-shrink-0 w-5 h-5 rounded-full bg-highlight/10 flex items-center justify-center mt-0.5">
                  <span className="w-2 h-2 rounded-full bg-highlight" />
                </span>
                <span className="text-gray-600">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}