import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const nicolasCaseStudy: CaseStudy = {
  id: 1,
  client: "Nicolas Facon",
  before: "1 seule mission sur Malt, difficulté à se démarquer",
  after: "5 missions réussies en développement mobile",
  duration: "1 mois",
  testimonial: "J'ai contacté François en début de mois pour optimiser mon profil Malt, le bilan est le suivant: Je suis passé de 4 propositions en 1 an à 2 propositions en 3 semaines (en août, un mois réputé pour être calme !). Le nombre de vues sur mon profil a quadruplé alors que le SEO de Malt est encore en train de faire son travail. François m'a fourni toutes les clés en main nécessaires pour mes recherches de missions, allant de la recherche d'un freelance par le client jusqu'à la signature du devis. Je vous recommande les yeux fermés François, son travail est super !",
  image: IMAGES.caseStudies.coaching.nicolas,
  success: "De junior à expert en 1 mois !"
};