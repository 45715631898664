import React from 'react';
import { Link } from 'react-router-dom';
import { Code, ArrowRight, Trophy, Star, Clock, Shield, Sparkles } from 'lucide-react';

export default function TechHero() {
  return (
    <div className="relative overflow-hidden bg-gradient-primary min-h-[90vh] flex items-center">
      <div className="absolute inset-0 bg-grid-primary opacity-10" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center space-y-8">
          {/* Badges */}
          <div className="flex justify-center gap-4 flex-wrap">
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-white/10 backdrop-blur border border-white/20 text-white space-x-2">
              <Trophy className="h-5 w-5 text-yellow-400" />
              <span className="font-semibold">Top 2 sur Malt</span>
            </div>
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-white/10 backdrop-blur border border-white/20 text-white space-x-2">
              <Clock className="h-5 w-5 text-green-400" />
              <span className="font-semibold">Disponible en Mars</span>
            </div>
          </div>

          {/* Titre principal */}
          <div className="space-y-6">
            <h1 className="text-5xl md:text-6xl font-extrabold text-white">
              <span className="block mb-4">
                De l'
                <span className="bg-gradient-to-r from-yellow-400 to-yellow-200 text-transparent bg-clip-text">expertise technique</span>
              </span>
              <span className="block">
                à la 
                <span className="bg-gradient-to-r from-green-400 to-emerald-300 text-transparent bg-clip-text"> création de valeur</span>
              </span>
            </h1>
            
            <div className="max-w-3xl mx-auto">
              <p className="text-xl md:text-2xl text-white/80">
                Je développe des applications performantes avec<br/>
                <span className="bg-gradient-to-r from-blue-400 to-cyan-300 text-transparent bg-clip-text font-bold">
                  une vision business et des résultats garantis
                </span>
              </p>
            </div>

            <div className="flex justify-center">
              <div className="inline-flex items-center px-6 py-3 rounded-full bg-white/5 backdrop-blur border border-white/10 text-white/90">
                <Sparkles className="h-5 w-5 text-yellow-400 mr-2" />
                <span>100% des projets livrés avec succès</span>
              </div>
            </div>
          </div>

          {/* Statistiques */}
          <div className="flex justify-center gap-8 text-white/90">
            <div className="flex items-center space-x-2">
              <Code className="h-5 w-5 text-blue-300" />
              <span>15+ projets livrés</span>
            </div>
            <div className="flex items-center space-x-2">
              <Shield className="h-5 w-5 text-green-300" />
              <span>Budget fixe garanti</span>
            </div>
            <div className="flex items-center space-x-2">
              <Star className="h-5 w-5 text-yellow-300" />
              <span>Noté 4.9/5</span>
            </div>
          </div>

          {/* CTAs */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-8">
            <Link
              to="/contact/tech"
              className="group bg-white text-primary px-8 py-4 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 flex items-center space-x-3 font-semibold w-full sm:w-auto justify-center transform hover:-translate-y-1"
            >
              <span>Discuter de votre projet</span>
              <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
            </Link>
            
            <Link
              to="/portfolio?tab=development"
              className="group bg-white/10 backdrop-blur text-white px-8 py-4 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 flex items-center space-x-3 font-semibold w-full sm:w-auto justify-center hover:bg-white/20 transform hover:-translate-y-1"
            >
              <span>Voir mes projets</span>
              <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}