import React from 'react';
import { Search, Layout, Code, Rocket } from 'lucide-react';

const steps = [
  {
    icon: Search,
    title: "Discovery Call",
    items: [
      "Analyse des besoins",
      "Évaluation technique",
      "Estimation budgétaire"
    ]
  },
  {
    icon: Layout,
    title: "Phase de Conception",
    items: [
      "Architecture détaillée",
      "Choix technologiques",
      "Planning précis"
    ]
  },
  {
    icon: Code,
    title: "Développement",
    items: [
      "Sprints hebdomadaires",
      "Démonstrations régulières",
      "Tests automatisés"
    ]
  },
  {
    icon: Rocket,
    title: "Livraison & Support",
    items: [
      "Formation utilisateur",
      "Documentation complète",
      "Maintenance évolutive"
    ]
  }
];

export default function TechMethodology() {
  return (
    <div className="py-16 bg-gradient-to-br from-white via-gray-50 to-primary/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
<h2 className="text-4xl font-extrabold bg-gradient-primary text-transparent bg-clip-text inline-block mb-4">Découvre l'approche qui transforme mes projets en succès         </h2>
          <p className="text-xl text-gray-600">
Une méthodologie éprouvée qui combine expertise technique et vision business
          </p>
        </div>

        <div className="relative">
          {/* Ligne de connexion */}
          <div className="absolute top-24 left-1/2 h-[calc(100%-6rem)] w-0.5 bg-gradient-to-b from-primary/20 to-primary hidden lg:block" />

          <div className="space-y-12">
            {steps.map((step, index) => (
              <div key={index} className="relative">
                <div className={`flex flex-col lg:flex-row items-center ${
                  index % 2 === 0 ? 'lg:flex-row-reverse' : ''
                } gap-8`}>
                  {/* Contenu */}
                  <div className={`lg:w-1/2 flex ${
                    index % 2 === 0 ? 'lg:justify-start' : 'lg:justify-end'
                  }`}>
                    <div className="relative w-full max-w-lg">
                      <div className="absolute inset-0 bg-gradient-primary opacity-20 rounded-2xl rotate-6" />
                      <div className="relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                        <div className="flex items-center space-x-4 mb-6">
                          <div className="flex-shrink-0 w-12 h-12 rounded-xl flex items-center justify-center bg-primary/10 text-primary">
                            <step.icon className="h-6 w-6" />
                          </div>
                          <h3 className="text-xl font-semibold text-secondary">
                            {step.title}
                          </h3>
                        </div>
                        <ul className="space-y-3">
                          {step.items.map((item, i) => (
                            <li key={i} className="flex items-center text-gray-600">
                              <span className="w-1.5 h-1.5 rounded-full bg-primary mr-2" />
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* Point de connexion */}
                  <div className="hidden lg:block absolute left-1/2 top-24 w-4 h-4 rounded-full bg-primary transform -translate-x-1/2" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}