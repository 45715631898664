import { Code, Server, Cpu } from 'lucide-react';
import type { Solution } from '../types';

export const solutions: Solution[] = [
  {
    icon: Code,
    title: "Architecture microservices",
    items: [
      "Décomposition du monolithe",
      "Services autonomes",
      "Communication asynchrone"
    ]
  },
  {
    icon: Server,
    title: "Pipeline de messaging",
    items: [
      "MQTT pour la collecte IoT",
      "Kafka pour le traitement temps réel",
      "Buffer et batch processing"
    ]
  },
  {
    icon: Cpu,
    title: "Infrastructure cloud",
    items: [
      "Containers Docker",
      "Orchestration Kubernetes",
      "Auto-scaling basé sur la charge"
    ]
  }
];