import type { Experience } from '../types';

export const kemenoExperience: Experience = {
  title: "Développeur IoT / Ingénieur DevOps",
  company: "Kemeno Technology",
  period: "Août 2023 - Août 2024",
  location: "Heidelberg, Allemagne · À distance",
  description: [
    "Conception et implémentation d'une architecture IoT complète pour la gestion de données massives en temps réel.",
    "Optimisation des coûts d'infrastructure de 200%."
  ],
  achievements: [
    "Réduction des coûts d'infrastructure : 200%",
    "Couverture des tests : 95%",
    "Scale de 0 à 100k messages/seconde",
    "Création d'un POC et validation marché en 3 mois",
    "Migration complète vers une architecture microservices",
    "Implémentation d'un système de messaging haute performance",
    "Automatisation totale du pipeline de déploiement"
  ]
};