import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const iotAppCaseStudy: CaseStudy = {
  id: 4,
  client: "Architecture IoT temps réel",
  before: "Infrastructure coûteuse et peu scalable",
  after: "Réduction des coûts de 200%, scale à 100k msg/s",
  duration: "6 mois",
  testimonial: "Une architecture robuste et performante qui nous a permis de réduire drastiquement nos coûts tout en améliorant nos performances.",
  image: IMAGES.caseStudies.development.iot,
  success: "Performance x100 !"
};