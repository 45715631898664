import React from 'react';
import ResultsTitle from './results/ResultsTitle';
import ResultsGrid from './results/ResultsGrid';

export default function ExpectedResults() {
  return (
    <div>
      <ResultsTitle />
      <ResultsGrid />
    </div>
  );
}