import React from 'react';
import { ArrowRight } from 'lucide-react';
import type { Journey } from './types';

interface JourneyCardProps {
  journey: Journey;
  onSelect: () => void;
}

export default function JourneyCard({ journey, onSelect }: JourneyCardProps) {
  const { icon: Icon, title, description, features, cta, type } = journey;
  const isPrimary = type === 'tech';

  return (
    <button
      onClick={onSelect}
      className={`group relative overflow-hidden bg-gradient-to-br ${
        isPrimary 
          ? 'from-primary/5 hover:from-primary/10' 
          : 'from-highlight/5 hover:from-highlight/10'
      } to-transparent p-10 rounded-2xl transition-all duration-300 transform hover:-translate-y-2 text-left w-full`}
    >
      {/* Background pattern */}
      <div className="absolute inset-0 bg-grid-primary opacity-10" />

      <div className="relative">
        {/* Icon */}
        <div className={`inline-flex items-center justify-center w-20 h-20 rounded-xl ${
          isPrimary
            ? 'bg-primary/10 text-primary group-hover:bg-primary/20'
            : 'bg-highlight/10 text-highlight group-hover:bg-highlight/20'
        } mb-8 transition-all duration-300 transform group-hover:scale-110`}>
          <Icon className="h-10 w-10" />
        </div>

        {/* Content */}
        <h3 className={`text-3xl font-bold mb-4 ${
          isPrimary ? 'text-primary' : 'text-highlight'
        }`}>
          {title}
        </h3>
        
        <p className="text-xl text-gray-600 mb-8">
          {description}
        </p>

        {/* Features */}
        <ul className="space-y-4 mb-10">
          {features.map((feature, idx) => (
            <li key={idx} className="flex items-center space-x-3 text-gray-600 group-hover:translate-x-1 transition-transform duration-300" style={{ transitionDelay: `${idx * 50}ms` }}>
              <span className={`w-2 h-2 rounded-full ${
                isPrimary ? 'bg-primary' : 'bg-highlight'
              }`} />
              <span className="text-lg">{feature}</span>
            </li>
          ))}
        </ul>

        {/* CTA */}
        <div className={`inline-flex items-center text-lg font-semibold ${
          isPrimary 
            ? 'text-primary group-hover:text-primary-dark' 
            : 'text-highlight group-hover:text-highlight-dark'
        }`}>
          <span>{cta}</span>
          <ArrowRight className="ml-2 h-6 w-6 group-hover:translate-x-2 transition-transform duration-300" />
        </div>
      </div>
    </button>
  );
}