import React from 'react';
import type { ProcessPhase as ProcessPhaseType } from '../types';

interface ProcessPhaseProps {
  phase: ProcessPhaseType;
  index: number;
}

export default function ProcessPhase({ phase, index }: ProcessPhaseProps) {
  return (
    <div className="relative">
      {/* Timeline connector */}
      {index > 0 && (
        <div className="absolute top-0 left-8 w-0.5 h-full -mt-8 bg-gradient-to-b from-primary/20 to-primary" />
      )}
      
      <div className="flex items-start space-x-4">
        {/* Phase number */}
        <div className="flex-shrink-0 w-16 h-16 bg-primary/10 rounded-xl flex items-center justify-center text-primary font-bold text-xl">
          {index + 1}
        </div>
        
        <div className="flex-grow">
          <div className="bg-white rounded-xl p-6 shadow-lg">
            <h4 className="text-xl font-semibold text-primary mb-4">
              {phase.title}
              <span className="ml-2 text-sm text-gray-500">({phase.duration})</span>
            </h4>
            
            <ul className="space-y-3">
              {phase.tasks.map((task, i) => (
                <li key={i} className="flex items-start space-x-2">
                  <span className="w-1.5 h-1.5 bg-primary rounded-full mt-2" />
                  <span className="text-gray-600">{task}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}