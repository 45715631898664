import type { Experience } from '../types';

export const bluefrogExperience: Experience = {
  title: "Intégrateur Webflow",
  company: "BlueFrogTechnology",
  period: "Novembre 2023 - Février 2024",
  location: "À distance",
  description: [
    "Création et optimisation de sites web performants sous Webflow, de la conception à la mise en production, avec un focus sur la conversion et l'expérience utilisateur."
  ],
  achievements: [
    "Temps de chargement réduit de 60%",
    "Score PageSpeed > 90",
    "Migration et création de +5 sites",
    "Refonte complète de landing pages pour maximiser les conversions",
    "Migration complexe de sites existants vers Webflow",
    "Optimisation des performances et de l'expérience utilisateur",
    "Intégration pixel-perfect des maquettes design"
  ]
};