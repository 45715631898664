import React from 'react';
import { Sparkles } from 'lucide-react';

export default function DifferentiatorsTitle() {
  return (
    <div className="text-center mb-16">
      <div className="inline-flex items-center justify-center space-x-3 mb-6">
        <div className="w-12 h-12 bg-gradient-to-br from-highlight to-highlight-dark rounded-xl flex items-center justify-center transform -rotate-6">
          <Sparkles className="h-6 w-6 text-white" />
        </div>
        <h3 className="text-3xl font-bold bg-gradient-to-r from-highlight to-highlight-dark text-transparent bg-clip-text">
          L'avantage décisif
        </h3>
      </div>
      <p className="text-xl text-gray-600 mb-4">
        Ce qui rend notre approche unique et efficace
      </p>
      <div className="max-w-2xl mx-auto px-6 py-4 bg-highlight/5 rounded-xl">
        <p className="text-gray-600">
          Découvre les 3 piliers qui ont permis à plus de 140 freelances de se démarquer 
          et d'attirer naturellement les meilleures opportunités
        </p>
      </div>
    </div>
  );
}