import React from 'react';
import TechHero from '../hero/TechHero';
import TechChallenges from './TechChallenges';
import TechBetterApproach from './TechBetterApproach';
import TechMethodology from './TechMethodology';
import TechGuarantees from './TechGuarantees';
import TechPortfolio from './TechPortfolio';
import TechFAQ from './TechFAQ';
import TechContact from '../../contact/TechContact';

export default function TechLanding() {
  return (
    <div className="min-h-screen bg-white">
      <TechHero />
      <TechChallenges />
      <TechBetterApproach />
      <TechMethodology />
      <TechGuarantees />
      <TechPortfolio />
      <TechFAQ />
      <TechContact />
    </div>
  );
}