import React from 'react';
import { Code, Database, Brain } from 'lucide-react';

const solutions = [
  {
    icon: Code,
    title: "Extraction intelligente",
    items: [
      "Algorithmes d'extraction multi-format",
      "Détection automatique des sections",
      "Normalisation des données"
    ]
  },
  {
    icon: Database,
    title: "Pipeline de données",
    items: [
      "Stockage optimisé MongoDB",
      "API REST avec FastAPI",
      "Cache intelligent Redis"
    ]
  },
  {
    icon: Brain,
    title: "Analyse IA",
    items: [
      "Intégration OpenAI GPT-4",
      "Analyse sémantique avancée",
      "Scoring automatique des profils"
    ]
  }
];

export default function Solution() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Solution mise en place</h2>
      
      <div className="grid md:grid-cols-3 gap-8">
        {solutions.map((solution, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-lg">
            <div className="flex flex-col h-full">
              <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
                <solution.icon className="h-6 w-6 text-primary" />
              </div>
              
              <h3 className="text-xl font-semibold text-primary mb-4">{solution.title}</h3>
              
              <ul className="space-y-3">
                {solution.items.map((item, i) => (
                  <li key={i} className="flex items-start space-x-2">
                    <span className="w-1.5 h-1.5 bg-primary rounded-full mt-2" />
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-primary/5 rounded-xl p-6">
        <pre className="text-sm text-gray-800 overflow-x-auto">
          <code>{`# Exemple d'extraction avec OpenAI
async def extract_cv_data(cv_text: str) -> dict:
    response = await openai.ChatCompletion.create(
        model="gpt-4",
        messages=[
            {"role": "system", "content": "Extract structured data from CV"},
            {"role": "user", "content": cv_text}
        ],
        functions=[{
            "name": "parse_cv",
            "parameters": {
                "type": "object",
                "properties": {
                    "skills": {"type": "array", "items": {"type": "string"}},
                    "experience": {"type": "array", "items": {"type": "object"}},
                    "education": {"type": "array", "items": {"type": "object"}}
                }
            }
        }]
    )
    return response.choices[0].message['function_call']['arguments']`}</code>
        </pre>
      </div>
    </div>
  );
}