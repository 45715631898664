import React from 'react';
import { FileSearch, MessageSquare, Crown, Rocket } from 'lucide-react';
import PhaseCard from './PhaseCard';

const phases = [
  {
    icon: FileSearch,
    title: "Foundation",
    period: "Semaines 1-2",
    items: [
      "Audit complet de ta présence en ligne",
      "Identification de ton positionnement unique",
      "Optimisation stratégique de tes profils",
      "Setup de tes outils de création de contenu"
    ]
  },
  {
    icon: MessageSquare,
    title: "Content Machine",
    period: "Semaines 3-4",
    items: [
      "Création de ton système de contenus",
      "Templates personnalisés à ton expertise",
      "Calendrier éditorial automatisé",
      "Workflow de production optimisé"
    ]
  },
  {
    icon: Crown,
    title: "Authority Builder",
    period: "Mois 2",
    items: [
      "Mise en place de ta stratégie de personal branding",
      "Création de contenus qui convertissent",
      "Optimisation de ta visibilité",
      "Construction de ta communauté"
    ]
  },
  {
    icon: Rocket,
    title: "Mission Machine",
    period: "Mois 3",
    items: [
      "Automatisation de ton pipeline de missions",
      "Système de qualification des prospects",
      "Scripts de négociation premium",
      "Processus de closing efficace"
    ]
  }
];

export default function TransformationPhases() {
  return (
    <div className="relative">
      {/* Timeline connector */}
      <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-gradient-to-b from-highlight/20 to-highlight hidden lg:block" />
      
      <div className="space-y-12">
        {phases.map((phase, index) => (
          <div key={index} className={`flex flex-col ${
            index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
          } gap-8 items-center`}>
            <PhaseCard {...phase} isRight={index % 2 === 0} />
          </div>
        ))}
      </div>
    </div>
  );
}