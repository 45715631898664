import React from 'react';
import type { Experience } from '../../data/types';
import { Building2, Calendar, MapPin } from 'lucide-react';

interface ExperienceCardProps {
  experience: Experience;
  index: number;
}

export default function ExperienceCard({ experience, index }: ExperienceCardProps) {
  return (
    <div className="relative">
      {/* Timeline connector */}
      {index !== 0 && (
        <div className="absolute top-0 -translate-y-full left-8 w-0.5 h-8 bg-gradient-to-b from-primary/20 to-primary" />
      )}
      
      <div className="bg-white/80 backdrop-blur rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 hover:border-primary/20">
        <div className="relative p-6">
          {/* Timeline dot */}
          <div className="absolute -left-3 top-8 w-6 h-6 rounded-full bg-gradient-primary border-4 border-white shadow-md" />
          
          <div className="space-y-4">
            <div>
              <h3 className="text-2xl font-bold bg-gradient-primary text-transparent bg-clip-text">
                {experience.title}
              </h3>
              <div className="mt-2 space-y-1.5">
                <div className="flex items-center text-gray-600">
                  <Building2 className="h-4 w-4 mr-2 text-accent" />
                  <span className="font-medium">{experience.company}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Calendar className="h-4 w-4 mr-2 text-accent" />
                  <span>{experience.period}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <MapPin className="h-4 w-4 mr-2 text-accent" />
                  <span>{experience.location}</span>
                </div>
              </div>
            </div>

            <div className="prose prose-gray">
              {experience.description.map((desc, index) => (
                <p key={index} className="text-gray-600">
                  {desc}
                </p>
              ))}
            </div>

            <div className="space-y-3">
              <h4 className="font-semibold text-secondary">Réalisations clés</h4>
              <ul className="grid gap-2">
                {experience.achievements.map((achievement, index) => (
                  <li key={index} className="flex items-start group">
                    <span className="inline-block w-2 h-2 rounded-full bg-highlight mt-2 mr-3 flex-shrink-0 group-hover:scale-125 transition-transform duration-300" />
                    <span className="text-gray-600 group-hover:text-gray-900 transition-colors duration-300">
                      {achievement}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}