import React from 'react';

export default function ResultsTitle() {
  return (
    <div className="text-center mb-16">
      <h3 className="text-3xl font-bold bg-gradient-to-r from-highlight to-highlight-dark text-transparent bg-clip-text mb-4">
        Ton évolution garantie
      </h3>
      <p className="mt-4 text-gray-600 max-w-2xl mx-auto">
        Un plan d'action précis pour transformer ton activité en 90 jours
      </p>
    </div>
  );
}