import React from 'react';
import { Crown, MessageSquare, Target } from 'lucide-react';
import SystemCard from './SystemCard';

const systemSteps = [
  {
    icon: Crown,
    title: "Authority",
    description: "Positionne-toi comme expert incontournable",
    features: [
      "Position d'expert établie",
      "Profils qui convertissent",
      "Personal branding qui attire"
    ],
    color: "primary"
  },
  {
    icon: MessageSquare,
    title: "Content",
    description: "Génère un flux constant d'opportunités",
    features: [
      "Machine à contenus automatisée",
      "Posts qui génèrent des leads",
      "Système de production scalable"
    ],
    color: "highlight"
  },
  {
    icon: Target,
    title: "Expertise",
    description: "Convertis ton expertise en revenus",
    features: [
      "Positionnement premium",
      "Pipeline toujours plein",
      "Clients qui viennent à toi"
    ],
    color: "accent"
  }
];

export default function SystemGrid() {
  return (
    <div className="grid md:grid-cols-3 gap-8">
      {systemSteps.map((step, index) => (
        <SystemCard key={index} {...step} index={index + 1} />
      ))}
    </div>
  );
}