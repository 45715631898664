import React, { useEffect } from 'react';
import Cal, { getCalApi } from "@calcom/embed-react";
import { Clock, Gift, Lightbulb, ArrowRight } from 'lucide-react';

export default function FreelanceContact() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({"namespace":"appel-de-decouverte"});
      cal("ui", {
        "cssVarsPerTheme": {
          "light": {"cal-brand": "#FC5656"},
          "dark": {"cal-brand": "#FC5656"}
        },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  const benefits = [
    {
      icon: Gift,
      text: "Call stratégique 100% gratuit"
    },
    {
      icon: Lightbulb,
      text: "Audit de ton profil offert"
    },
    {
      icon: ArrowRight,
      text: "Plan d'action personnalisé"
    },
    {
      icon: Clock,
      text: "30 minutes pour définir ta stratégie"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-highlight/5 py-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Background decoration */}
        <div className="absolute inset-0 bg-grid-primary opacity-10" />
        
        <div className="relative">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-highlight text-transparent bg-clip-text inline-block">
              Passe à l'étape supérieure dès maintenant
            </h1>
            <p className="mt-4 text-xl text-gray-600">
              30 minutes qui peuvent transformer ton activité freelance
            </p>
          </div>

          {/* Avantages */}
          <div className="max-w-4xl mx-auto mb-12">
            <div className="grid grid-cols-2 gap-6">
              {benefits.map((benefit, index) => (
                <div 
                  key={index}
                  className="flex items-center space-x-3 bg-white/80 backdrop-blur rounded-xl p-4 shadow-sm hover:shadow-md transition-all duration-300"
                >
                  <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-highlight/10 flex items-center justify-center">
                    <benefit.icon className="h-5 w-5 text-highlight" />
                  </div>
                  <span className="text-gray-600">{benefit.text}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Cal.com Integration */}
          <div className="bg-white/80 backdrop-blur rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 hover:border-highlight/20 p-8 max-w-4xl mx-auto">
            <Cal
              namespace="appel-de-decouverte"
              calLink="francois-behague/appel-de-decouverte"
              style={{
                width: "100%",
                height: "100%",
                minHeight: "800px",
                border: "none",
                borderRadius: "0.75rem",
              }}
              config={{
                layout: "month_view"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}