import React from 'react';
import { Link } from 'react-router-dom';
import { Users, ArrowRight, Trophy, Star, Target, Sparkles } from 'lucide-react';

export default function FreelanceHero() {
  return (
    <div className="relative overflow-hidden bg-gradient-secondary min-h-[90vh] flex items-center">
      <div className="absolute inset-0 bg-grid-primary opacity-10" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center space-y-8">
          {/* Badges */}
          <div className="flex justify-center gap-4 flex-wrap">
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-white/10 backdrop-blur border border-white/20 text-white space-x-2">
              <Trophy className="h-5 w-5 text-yellow-400" />
              <span className="font-semibold">Top 1% sur LinkedIn</span>
            </div>
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-white/10 backdrop-blur border border-white/20 text-white space-x-2">
              <Target className="h-5 w-5 text-green-400" />
              <span className="font-semibold">2 places disponibles</span>
            </div>
          </div>

          {/* Titre principal */}
          <div className="space-y-6">
            <h1 className="text-5xl md:text-6xl font-extrabold text-white">
              <span className="block mb-4">
                Créé ton 
                <span className="bg-gradient-to-r from-yellow-400 to-yellow-200 text-transparent bg-clip-text"> système</span>
              </span>
              <span className="block">
                d'acquisition de missions
                <span className="bg-gradient-to-r from-green-400 to-emerald-300 text-transparent bg-clip-text"> automatique</span>
              </span>
            </h1>
            
            <div className="max-w-3xl mx-auto">
              <p className="text-xl md:text-2xl text-white/80">
                Décroche des missions premium sans prospection <br/>
                <span className="bg-gradient-to-r from-blue-400 to-cyan-300 text-transparent bg-clip-text font-bold">
                  même en étant junior
                </span>
              </p>
            </div>

            
          </div>

          {/* Statistiques */}
          <div className="flex justify-center gap-8 text-white/90">
            <div className="flex items-center space-x-2">
              <Users className="h-5 w-5 text-green-300" />
              <span>140+ freelances accompagnés</span>
            </div>
            <div className="flex items-center space-x-2">
              <Target className="h-5 w-5 text-blue-300" />
              <span>10-20 leads par mois</span>
            </div>
            <div className="flex items-center space-x-2">
              <Star className="h-5 w-5 text-yellow-300" />
              <span>100% de réussite</span>
            </div>
          </div>

          {/* CTAs */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-8">
            <Link
              to="/contact/freelance"
              className="group bg-white text-highlight px-8 py-4 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 flex items-center space-x-3 font-semibold w-full sm:w-auto justify-center transform hover:-translate-y-1"
            >
              <span>Réserver un call stratégique</span>
              <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
            </Link>
            
            <Link
              to="/portfolio?tab=coaching"
              className="group bg-white/10 backdrop-blur text-white px-8 py-4 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 flex items-center space-x-3 font-semibold w-full sm:w-auto justify-center hover:bg-white/20 transform hover:-translate-y-1"
            >
              <span>Voir les success stories</span>
              <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}