import React from 'react';
import type { LucideIcon } from 'lucide-react';

interface BenefitCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

export default function BenefitCard({ icon: Icon, title, description }: BenefitCardProps) {
  return (
    <div className="group relative transform hover:-translate-y-2 transition-all duration-300">
      <div className="absolute inset-0 bg-gradient-primary opacity-5 rounded-xl transform rotate-1" />
      <div className="relative bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
        <div className="flex items-center justify-center w-14 h-14 rounded-xl bg-gradient-to-br from-primary/10 to-primary/5 text-primary mx-auto mb-6 group-hover:scale-110 transition-transform duration-300">
          <Icon className="h-7 w-7" />
        </div>
        <h5 className="text-xl font-bold text-secondary mb-3 text-center group-hover:text-primary transition-colors duration-300">
          {title}
        </h5>
        <p className="text-gray-600 text-center leading-relaxed">
          {description}
        </p>
      </div>
    </div>
  );
}