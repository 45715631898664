import React from 'react';
import SystemTitle from './system/SystemTitle';
import SystemGrid from './system/SystemGrid';

export default function FreelanceSystem() {
  return (
    <div className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SystemTitle />
        <SystemGrid />
      </div>
    </div>
  );
}