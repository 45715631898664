import React from 'react';

export default function WarningQuote() {
  return (
    <div className="max-w-3xl mx-auto">
      <div className="relative transform hover:-translate-y-2 transition-all duration-300">
        <div className="absolute inset-0 bg-danger-100 rounded-xl transform rotate-1 opacity-70" />
        <div className="relative bg-white rounded-xl p-8 shadow-lg border border-danger">
          <div className="flex flex-col items-center">

            <p className="text-xl text-danger italic text-center font-medium">
              "J'étais persuadé que mes compétences techniques suffiraient... Après 6 mois de galère et 40K€ de revenus perdus, j'ai compris que j'avais tout faux."
            </p>
            <div className="mt-6 inline-flex items-center justify-center px-4 py-2 bg-danger-50 rounded-full">
              <p className="text-danger text-sm font-medium">
                - Thomas V
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}