import React from 'react';
import ResultCard from './ResultCard';

const monthlyResults = [
  {
    month: "Mois 1",
    title: "Foundation",
    results: [
      "Profil optimisé qui attire naturellement",
      "Premiers contenus à forte valeur ajoutée",
      "Positionnement d'expert validé"
    ],
    highlight: "Visibilité x3",
    stats: {
      views: "500+",
      leads: "3-5",
      label: "vues/semaine"
    }
  },
  {
    month: "Mois 2",
    title: "Accélération",
    results: [
      "3-4 propositions qualifiées/semaine",
      "Communauté en croissance rapide",
      "Premiers clients premium signés"
    ],
    highlight: "Revenus +50%",
    stats: {
      views: "1000+",
      leads: "8-10",
      label: "vues/semaine"
    }
  },
  {
    month: "Mois 3",
    title: "Maîtrise",
    results: [
      "Pipeline de missions sur 6 mois",
      "Sélection des meilleurs projets",
      "TJM augmenté de 50-100%"
    ],
    highlight: "TJM doublé",
    stats: {
      views: "2000+",
      leads: "12-15",
      label: "vues/semaine"
    }
  }
];

export default function ResultsGrid() {
  return (
    <div className="grid md:grid-cols-3 gap-8">
      {monthlyResults.map((result, index) => (
        <ResultCard 
          key={index} 
          {...result} 
          index={index + 1}
        />
      ))}
    </div>
  );
}