import React from 'react';

interface CaseStudyHeroProps {
  image: string;
  title: string;
}

export default function CaseStudyHero({ image, title }: CaseStudyHeroProps) {
  return (
    <div className="relative mx-auto w-2/3">
      <div className="relative aspect-[16/9] rounded-2xl overflow-hidden bg-secondary shadow-lg">
        <img
          src={image}
          alt={title}
          className="absolute inset-0 w-full h-full object-cover object-center rounded-2xl"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-secondary/90 via-secondary/50 to-transparent rounded-2xl" />
        <div className="absolute bottom-8 left-8 right-8">
          <h1 className="text-4xl font-bold text-white text-center">{title}</h1>
        </div>
      </div>
    </div>
  );
}