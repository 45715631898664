import React from 'react';
import { Award } from 'lucide-react';

interface CaseStudyTestimonialProps {
  testimonial: string;
}

export default function CaseStudyTestimonial({ testimonial }: CaseStudyTestimonialProps) {
  return (
    <div className="bg-white/80 backdrop-blur rounded-xl shadow-lg p-8">
      <div className="flex items-start space-x-4">
        <Award className="h-6 w-6 text-primary flex-shrink-0 mt-1" />
        <div>
          <h3 className="text-xl font-semibold text-primary mb-4">Témoignage client</h3>
          <blockquote className="text-gray-600 italic border-l-4 border-primary/20 pl-4">
            "{testimonial}"
          </blockquote>
        </div>
      </div>
    </div>
  );
}