import { developmentCaseStudies, coachingCaseStudies } from '../data/caseStudies';
import type { PortfolioTab } from '../types/portfolio';
import type { CaseStudy } from '../types/caseStudy';

export function getStudiesByType(type: PortfolioTab): CaseStudy[] {
  return type === 'coaching' ? coachingCaseStudies : developmentCaseStudies;
}

export function getStudyUrl(id: number, type: 'coaching' | 'development'): string {
  const specialRoutes: Record<number, string> = {
    7: '/portfolio/development/react-portfolio',
    4: '/portfolio/development/iot-architecture'
  };

  return specialRoutes[id] || `/portfolio/${type}/${id}`;
}