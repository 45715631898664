import React from 'react';
import { Code, Star, Trophy } from 'lucide-react';

const stats = [
  {
    icon: Code,
    value: "15+",
    label: "projets livrés"
  },
  {
    icon: Star,
    value: "4.9/5",
    label: "satisfaction client"
  },
  {
    icon: Trophy,
    value: "100%",
    label: "projets réussis"
  }
];

export default function ProjectStats() {
  return (
    <div className="grid md:grid-cols-3 gap-8 mb-12">
      {stats.map((stat, index) => (
        <div 
          key={index}
          className="relative transform hover:-translate-y-2 transition-all duration-300"
        >
          <div className="absolute inset-0 bg-gradient-primary opacity-5 rounded-xl transform rotate-1" />
          <div className="relative bg-white/80 backdrop-blur rounded-xl p-6 text-center shadow-lg">
            <div className="inline-flex items-center justify-center w-14 h-14 rounded-xl bg-gradient-to-br from-primary/10 to-primary/5 text-primary mb-4">
              <stat.icon className="h-7 w-7" />
            </div>
            <div className="text-3xl font-bold text-secondary mb-2">{stat.value}</div>
            <div className="text-gray-600">{stat.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
}