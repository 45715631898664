import React from 'react';
import { resultMetrics } from './data/metrics';
import MetricsGrid from './metrics/MetricsGrid';

const improvements = [
  {
    label: "Latence",
    before: "500ms",
    after: "50ms"
  },
  {
    label: "Disponibilité",
    before: "99.5%",
    after: "99.99%"
  },
  {
    label: "Déploiement",
    before: "Manuel",
    after: "Automatisé"
  }
];

export default function Results() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Résultats obtenus</h2>

      <MetricsGrid metrics={resultMetrics} columns={3} />

      <div className="bg-white rounded-xl p-8 shadow-lg">
        <h3 className="text-xl font-semibold text-primary mb-6">Améliorations techniques</h3>
        <div className="space-y-4">
          {improvements.map((improvement, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="w-1/3 text-gray-600">{improvement.label}</div>
              <div className="flex-1 flex items-center space-x-4">
                <div className="bg-red-100 px-3 py-1 rounded-full text-red-700">
                  {improvement.before}
                </div>
                <div className="text-gray-400">→</div>
                <div className="bg-green-100 px-3 py-1 rounded-full text-green-700">
                  {improvement.after}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}