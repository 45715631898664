import React from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import TechContact from '../components/contact/TechContact'; 
import FreelanceContact from '../components/contact/FreelanceContact'; 
import UserJourneySelection from "../components/home/journey/UserJourneySelection";
import { useUser } from '../contexts/UserContext';

export default function Contact() {
  const [searchParams] = useSearchParams();
  const { userType } = useUser();
  
  // Get type from URL params or user context
  const type = searchParams.get('type') || userType;

  // Redirect based on type
  if (type === 'tech') {
    return <Navigate to="/contact/tech" replace />;
  }
  
  if (type === 'freelance') {
    return <Navigate to="/contact/freelance" replace />;
  }

  // If no type is specified, show the journey selection
  return <UserJourneySelection />;
}