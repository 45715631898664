import type { Experience } from '../types';

export const freelanceExperience: Experience = {
  title: "Développeur Web / Ingénieur DevOps",
  company: "François Behague · Freelance",
  period: "Août 2020 - Présent",
  location: "Lillers, Hauts-de-France, France · À distance",
  description: [
    "Conception et développement full-stack pour PME/TPE, spécialisé dans les architectures backend robustes, le déploiement d'infrastructures cloud optimisées et les interfaces modernes React.",
    "• +5 ans d'expérience en développement full-stack et DevOps",
    "• +15 projets déployés en production",
    "• Réduction moyenne des coûts d'infrastructure de 60%"
  ],
  achievements: [
    "Conception d'architectures microservices hautement scalables",
    "Développement d'interfaces utilisateur modernes et performantes",
    "Implémentation de pipelines CI/CD automatisés",
    "Développement d'APIs REST performantes et sécurisées",
    "Migration d'infrastructures monolithiques vers le cloud",
    "Mise en place de systèmes de monitoring et d'alerting",
    "Stack technique : React, TypeScript, Next.js, TailwindCSS, Golang, Node.js, PHP, Docker, Kubernetes, AWS, Terraform, Ansible"
  ]
};