import React from 'react';
import WarningQuote from './agitation/WarningQuote';
import WarningSignals from './agitation/WarningSignals';
import Consequences from './agitation/Consequences';

export default function FreelanceAgitation() {
  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-red-50/30">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold bg-gradient-danger from-highlight to-highlight-dark text-transparent bg-clip-text mb-4">           

            La vérité que personne ne te dit
          </h2>
          <p className="text-xl text-gray-600">
            Découvre pourquoi la plupart des freelances restent bloqués malgré leur expertise
          </p>
        </div>

        {/* Main Content */}
        <div className="space-y-16">
          <WarningQuote />
          <WarningSignals />
          <Consequences />
        </div>

        {/* Transition to Solution */}
        <div className="text-center mt-16">
          <h3 className="text-2xl font-bold bg-gradient-to-r from-highlight to-highlight-dark text-transparent bg-clip-text">
            Mais il existe une meilleure approche...
          </h3>
        </div>
      </div>
    </div>
  );
}