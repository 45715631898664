import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const nycticoraxCaseStudy: CaseStudy = {
  id: 7,
  client: "Portfolio de sites React",
  before: "Développement lent et code dupliqué",
  after: "4 sites livrés en 4 mois, 60% de réutilisation",
  duration: "4 mois",
  testimonial: "La mise en place de la librairie de composants et des bonnes pratiques React nous a permis d'accélérer drastiquement notre développement tout en maintenant une qualité constante.",
  image: IMAGES.caseStudies.development.nycticorax,
  success: "Productivité x2.5 !"
};