import React from 'react';
import { Gauge, Clock, Target } from 'lucide-react';
import MetricsGrid from './metrics/MetricsGrid';
import { headerMetrics } from './data/metrics';

export default function CaseStudyHeader() {
  return (
    <div className="text-center space-y-8">
      <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text">
        Architecture IoT temps réel
      </h1>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        D'une preuve de concept à une solution scalable
      </p>

      <MetricsGrid metrics={headerMetrics} />
    </div>
  );
}