import { capygencyExperience } from './capygency';
import { kemenoExperience } from './kemeno';
import { bluefrogExperience } from './bluefrog';
import { clicnworkExperience } from './clicnwork';
import { axeCapitalExperience } from './axecapital';
import { freelanceExperience } from './freelance';
import { nycticoraxExperience } from './nycticorax';
import type { Experience } from '../types';

// Trier les expériences par date de fin de mission (la plus récente en premier)
export const experiences: Experience[] = [
  capygencyExperience,    // Janvier 2024 - Présent
  freelanceExperience,    // Août 2020 - Présent
  kemenoExperience,       // Août 2023 - Août 2024
  bluefrogExperience,     // Novembre 2023 - Février 2024
  clicnworkExperience,    // Janvier 2024 - Février 2024
  axeCapitalExperience,   // Mai 2023 - Juillet 2023
  nycticoraxExperience    // Janvier 2021 - Avril 2021
];