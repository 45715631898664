import React from 'react';
import type { LucideIcon } from 'lucide-react';
import { ArrowRight } from 'lucide-react';

interface DifferentiatorCardProps {
  icon: LucideIcon;
  title: string;
  features: string[];
  highlight: string;
  index: number;
}

export default function DifferentiatorCard({ icon: Icon, title, features, highlight, index }: DifferentiatorCardProps) {
  return (
    <div className="group relative transform hover:-translate-y-2 transition-all duration-300">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-gradient-highlight opacity-5 rounded-2xl transform rotate-1" />
      
      <div className="relative bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border border-highlight/10 overflow-hidden">
        {/* Header Section */}
        <div className="bg-gradient-to-br from-highlight/10 to-highlight/5 p-8">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-4">
              <div className="w-12 h-12 bg-white rounded-xl flex items-center justify-center shadow-md group-hover:scale-110 transition-transform duration-300">
                <Icon className="h-6 w-6 text-highlight" />
              </div>
              <span className="text-4xl font-bold text-highlight/20">0{index}</span>
            </div>
            <div className="bg-white px-4 py-2 rounded-full shadow-md">
              <span className="text-sm font-semibold text-highlight">{highlight}</span>
            </div>
          </div>
          <h4 className="text-xl font-bold text-secondary group-hover:text-highlight transition-colors duration-300">
            {title}
          </h4>
        </div>

        {/* Features Section */}
        <div className="p-8">
          <ul className="space-y-4">
            {features.map((feature, idx) => (
              <li key={idx} className="flex items-center space-x-3 group/item">
                <div className="w-8 h-8 rounded-lg bg-highlight/5 flex items-center justify-center group-hover/item:bg-highlight/10 transition-colors duration-300">
                  <ArrowRight className="h-4 w-4 text-highlight transform group-hover/item:translate-x-1 transition-transform duration-300" />
                </div>
                <span className="text-gray-600 group-hover/item:text-gray-900 transition-colors duration-300">
                  {feature}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* Bottom Decoration */}
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-highlight/30 via-highlight to-highlight/30 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
      </div>
    </div>
  );
}