import React from 'react';
import { Settings, Crown, Clock } from 'lucide-react';
import DifferentiatorCard from './DifferentiatorCard';

const differentiators = [
  {
    icon: Settings,
    title: "Un Système Automatisé",
    features: [
      "Pipeline de missions qui se remplit tout seul",
      "Leads qualifiés qui viennent à toi",
      "Plus besoin de prospecter activement"
    ],
    highlight: "Gain de temps x3"
  },
  {
    icon: Crown,
    title: "Une Autorité Naturelle",
    features: [
      "Positionnement d'expert incontestable",
      "Communauté engagée qui te recommande",
      "Personal branding qui convertit"
    ],
    highlight: "Visibilité x5"
  },
  {
    icon: Clock,
    title: "Une Liberté Totale",
    features: [
      "Choix des meilleures missions",
      "Revenus prévisibles et croissants",
      "Temps retrouvé pour travailler"
    ],
    highlight: "Stress divisé par 2"
  }
];

export default function DifferentiatorsGrid() {
  return (
    <div className="grid lg:grid-cols-3 gap-8">
      {differentiators.map((diff, index) => (
        <DifferentiatorCard key={index} {...diff} index={index + 1} />
      ))}
    </div>
  );
}