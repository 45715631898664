import { Gauge, Clock, Target, TrendingUp, Shield } from 'lucide-react';
import type { Metric } from '../types';

export const headerMetrics: Metric[] = [
  {
    icon: Gauge,
    value: "100k",
    label: "messages/seconde"
  },
  {
    icon: Clock,
    value: "3 mois",
    label: "pour le POC"
  },
  {
    icon: Target,
    value: "-200%",
    label: "coûts d'infrastructure"
  }
];

export const resultMetrics: Metric[] = [
  {
    icon: Gauge,
    value: "50ms",
    label: "latence moyenne",
    subtitle: "réduite de 500ms"
  },
  {
    icon: Shield,
    value: "99.99%",
    label: "disponibilité",
    subtitle: "uptime garanti"
  },
  {
    icon: TrendingUp,
    value: "4 mois",
    label: "break-even",
    subtitle: "retour sur investissement"
  }
];