// src/pages/Home.tsx
import React from 'react';
import { useUser } from '../contexts/UserContext';
import TechLanding from '../components/home/tech/TechLanding';
import FreelanceLanding from '../components/home/freelance/FreelanceLanding';

// Création du composant de sélection directement dans le dossier home/journey
import HomepageJourneySelection from '../components/home/journey/HomepageJourneySelection';

export default function Home() {
  const { userType } = useUser();

  if (userType === 'tech') {
    return (
      <div className="min-h-screen bg-white">
        <TechLanding />
      </div>
    );
  }

  if (userType === 'freelance') {
    return (
      <div className="min-h-screen bg-white">
        <FreelanceLanding />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <HomepageJourneySelection />
    </div>
  );
}