import React, { useState, useEffect } from 'react';
import { Menu, X, Briefcase, Home, Code, GraduationCap, BookOpen, ArrowLeft, MessageCircle } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext'; 

const navigation = [
  { name: 'Accueil', href: '/', icon: Home },
  { name: 'Services', href: '/services', icon: Briefcase },
  { name: 'Expériences', href: '/experiences', icon: GraduationCap },
  { name: 'Portfolio', href: '/portfolio', icon: Code },
  { name: 'Ressources', href: '/ressources', icon: BookOpen },
  { name: 'Contact', href: '/contact', icon: MessageCircle },
];

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const { userType, setUserType } = useUser();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const isActive = (path: string) => location.pathname === path;

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled || isOpen ? 'bg-white/95 backdrop-blur-md shadow-lg' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <span className="text-2xl font-bold bg-gradient-primary text-transparent bg-clip-text">
                François Behague
              </span>
            </Link>
          </div>

          {/* Bouton retour parcours (desktop) */}
          {userType && (
            <button
              onClick={() => setUserType(null)}
              className="hidden md:flex items-center space-x-2 px-3 py-2 rounded-lg transition-all duration-300 text-primary hover:bg-primary/5"
            >
              <ArrowLeft className="h-5 w-5" />
              <span>Changer de parcours</span>
            </button>
          )}

          {/* Menu desktop */}
          <div className="hidden md:flex md:items-center md:space-x-4">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`flex items-center space-x-2 px-3 py-2 rounded-lg transition-all duration-300 ${
                  isActive(item.href)
                    ? 'text-primary bg-primary/10'
                    : 'text-gray-600 hover:text-primary hover:bg-primary/5'
                }`}
              >
                <item.icon className="h-5 w-5" />
                <span>{item.name}</span>
              </Link>
            ))}
          </div>

          {/* Bouton menu mobile */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-lg text-gray-600 hover:text-primary hover:bg-primary/5 transition-colors duration-300"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Ouvrir le menu</span>
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Menu mobile */}
      <div 
        className={`md:hidden fixed right-4 top-20 w-64 bg-white rounded-xl shadow-xl transform transition-all duration-300 ease-in-out ${
          isOpen 
            ? 'translate-y-0 opacity-100 scale-100' 
            : '-translate-y-4 opacity-0 scale-95 pointer-events-none'
        }`}
      >
        <div className="p-4 space-y-2">
          {/* Bouton retour parcours (mobile) */}
          {userType && (
            <button
              onClick={() => setUserType(null)}
              className="flex w-full items-center space-x-2 px-4 py-3 rounded-lg text-primary hover:bg-primary/5 transition-all duration-300"
            >
              <ArrowLeft className="h-5 w-5" />
              <span>Changer de parcours</span>
            </button>
          )}

          {/* Items du menu mobile */}
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={`flex items-center space-x-3 px-4 py-3 rounded-lg transition-all duration-300 ${
                isActive(item.href)
                  ? 'text-primary bg-primary/10'
                  : 'text-gray-600 hover:text-primary hover:bg-primary/5'
              }`}
            >
              <item.icon className="h-5 w-5" />
              <span>{item.name}</span>
            </Link>
          ))}
        </div>
      </div>

      {/* Overlay pour fermer le menu au clic en dehors */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black/20 backdrop-blur-sm md:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}
    </nav>
  );
}