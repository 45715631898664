import React from 'react';
import ProcessPhase from './ProcessPhase';
import { developmentPhases } from '../data/process';

export default function DevelopmentProcess() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Processus de développement</h2>
      
      <div className="space-y-8">
        {developmentPhases.map((phase, index) => (
          <ProcessPhase key={index} phase={phase} index={index} />
        ))}
      </div>
    </div>
  );
}