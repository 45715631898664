import React from 'react';
import { Book } from 'lucide-react';
import type { Resource } from '../../data/types';

interface ResourceCardProps {
  resource: Resource;
}

export default function ResourceCard({ resource }: ResourceCardProps) {
  return (
    <div className="group relative transform hover:-translate-y-2 transition-all duration-300">
      <div className="absolute inset-0 bg-gradient-primary opacity-5 rounded-2xl transform rotate-1" />
      
      <div className="relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
        <div className="flex items-start space-x-4 mb-6">
          <div className="flex-shrink-0 w-16 h-16 rounded-xl bg-primary/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
            <Book className="h-8 w-8 text-primary" />
          </div>
          <div>
            <h3 className="text-2xl font-bold text-secondary group-hover:text-primary transition-colors duration-300">
              {resource.title}
            </h3>
            <p className="text-gray-600 mt-1">
              {resource.description}
            </p>
          </div>
        </div>

        <ul className="space-y-3 mb-8">
          {resource.features.map((feature, idx) => (
            <li key={idx} className="flex items-center space-x-3">
              <span className="w-1.5 h-1.5 rounded-full bg-primary" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4 text-sm text-gray-500">
            <span>{resource.pages} pages</span>
            <span>•</span>
            <span>{resource.format}</span>
          </div>
          <a
            href={resource.href}
            className="inline-flex items-center px-4 py-2 text-primary hover:text-white bg-primary/5 hover:bg-primary rounded-lg transition-all duration-300"
          >
            <span>Télécharger</span>
          </a>
        </div>
      </div>
    </div>
  );
}