import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { coachingCaseStudies, developmentCaseStudies } from '../data/caseStudies';
import CaseStudyHero from '../components/portfolio/CaseStudyHero';
import CaseStudyResults from '../components/portfolio/CaseStudyResults';
import CaseStudyTestimonial from '../components/portfolio/CaseStudyTestimonial';
import CaseStudyMethodology from '../components/portfolio/CaseStudyMethodology';

export default function CaseStudyDetails() {
  const { type, id } = useParams<{ type: string; id: string }>();
  const navigate = useNavigate();

  const studies = type === 'coaching' ? coachingCaseStudies : developmentCaseStudies;
  const study = studies.find(s => s.id === Number(id));

  if (!study) {
    navigate('/portfolio');
    return null;
  }

  const isNicolasCase = study.id === 1;

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-primary/5 py-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 bg-grid-primary opacity-10" />
        
        <div className="relative">
          <Link
            to="/portfolio"
            className="inline-flex items-center text-primary hover:text-primary-dark transition-colors duration-300"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Retour aux études de cas
          </Link>

          <div className="mt-8 space-y-12">
            <CaseStudyHero
              image={study.image}
              title={study.client}
            />

            <div className="grid lg:grid-cols-2 gap-8">
              <div className="space-y-6">
                <CaseStudyResults
                  before={study.before}
                  after={study.after}
                  title={study.success}
                />
                <CaseStudyTestimonial
                  testimonial={study.testimonial}
                />
              </div>

              <CaseStudyMethodology
                isNicolasCase={isNicolasCase}
                type={type as 'coaching' | 'development'}
                projectId={study.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}