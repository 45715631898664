import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const ronanCaseStudy: CaseStudy = {
  id: 4,
  client: "Ronan Jaffré",
  before: "8 vues par mois",
  after: "1135 vues par mois",
  duration: "2 mois",
  testimonial: "En prenant le coaching de François, celà m'a fait exploser le nombre de vues sur Malt. j'ai eu 3 leads en 1 semaine et je le recommande à tout le monde !",
  image: IMAGES.caseStudies.coaching.ronan,
  success: "1100 vues en un mois !"
};