import React from 'react';

export default function IntroSection() {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-radial from-primary/5 to-transparent opacity-50" />
      <div className="relative text-center max-w-3xl mx-auto bg-white/80 backdrop-blur rounded-2xl p-8 shadow-lg">
        <p className="text-2xl text-gray-600 italic font-light">
          Et je comprends exactement ce que tu ressens...
        </p>
        <p className="mt-6 text-lg text-gray-600 leading-relaxed">
          Après avoir accompagné plus de <span className="font-semibold text-primary">150 entrepreneurs</span>, 
          j'ai identifié le véritable problème : ce n'est pas le code qui fait échouer les projets.
        </p>
        <div className="mt-8 bg-gradient-to-r from-secondary/5 to-transparent p-6 rounded-xl">
          <p className="text-2xl font-bold text-secondary mb-3">
            La vraie raison ?
          </p>
          <p className="text-xl text-gray-600">
            Les développeurs traditionnels se concentrent sur le code, pas sur ton succès business.
          </p>
        </div>
      </div>
    </div>
  );
}