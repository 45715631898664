import React from 'react';

export default function SystemTitle() {
  return (
    <div className="text-center mb-16">
      <h2 className="text-4xl font-extrabold bg-gradient-to-r from-highlight to-highlight-dark text-transparent bg-clip-text mb-4">
        Le système A.C.E pour attirer les missions passivement
      </h2>
      <p className="mt-4 text-xl text-gray-600">
        (Authority, Content, Expertise)
      </p>
    </div>
  );
}