import type { CaseStudy } from '../../types';
import { IMAGES } from '../../images';

export const sophieCaseStudy: CaseStudy = {
  id: 2,
  client: "Sophie Potier",
  before: "Profil peu visible, pas de missions",
  after: "Profil optimisé, visibilité accrue, supermalter 2",
  duration: "1 mois",
  testimonial: "Je recommande vivement François, il a été capable de comprendre mes attentes et d'optimiser correctement mon profil Malt. Il m'a également permis de mieux comprendre la plateforme et son fonctionnement technique.",
  image: IMAGES.caseStudies.coaching.sophie,
  success: "Supermalter en 4 semaines !"
};