import type { ProcessPhase } from '../types';

export const developmentPhases: ProcessPhase[] = [
  {
    title: "POC",
    duration: "3 mois",
    tasks: [
      "Architecture initiale",
      "Tests de charge",
      "Validation marché"
    ]
  },
  {
    title: "Migration",
    duration: "2 mois",
    tasks: [
      "Découpage en microservices",
      "Setup infrastructure Kubernetes",
      "Tests d'intégration"
    ]
  },
  {
    title: "Optimisation",
    duration: "3 mois",
    tasks: [
      "Fine-tuning performance",
      "Optimisation coûts",
      "Monitoring avancé"
    ]
  },
  {
    title: "Scale",
    duration: "4 mois",
    tasks: [
      "Montée en charge",
      "Documentation",
      "Formation équipe client"
    ]
  }
];