export const successPoints = [
  "Architecture monorepo dès le début",
  "Design system évolutif",
  "Tests automatisés systématiques"
];

export const improvementPoints = [
  "Meilleure planification des dépendances",
  "Documentation plus précoce",
  "Plus de tests de performance en amont"
];