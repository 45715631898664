import React from 'react';
import JourneyHeader from './header/JourneyHeader';
import JourneyCards from './cards/JourneyCards';
import BackgroundDecoration from './background/BackgroundDecoration';

export default function HomepageJourneySelection() {
  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Background decorations */}
      <BackgroundDecoration />
      
      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="relative space-y-16">
          <JourneyHeader />
          <JourneyCards />
        </div>
      </div>
    </div>
  );
}