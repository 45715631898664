import React from 'react';
import ResourcesHeader from '../components/resources/ResourcesHeader';
import ResourcesList from '../components/resources/ResourcesList';
import AuditsSection from '../components/resources/AuditsSection';

export default function Resources() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-primary/5 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ResourcesHeader />
        <div className="space-y-24">
          <ResourcesList />
          <AuditsSection />
        </div>
      </div>
    </div>
  );
}