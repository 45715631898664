import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import ProjectStats from "./portfolio/ProjectStats";
import { developmentCaseStudies } from "../../../data/caseStudies";
import CaseStudyCard from "../../portfolio/cards/CaseStudyCard";

export default function TechPortfolio() {
  // Sélection spécifique des projets que nous voulons afficher
  const selectedProjects = developmentCaseStudies.filter(study => 
    [4, 6, 7].includes(study.id) // IDs des projets à afficher
  );

  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-primary/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold bg-gradient-primary text-transparent bg-clip-text inline-block mb-4">
            Portfolio & études de cas
          </h2>
          <p className="text-xl text-gray-600">
            Des projets concrets, des résultats mesurables
          </p>
        </div>
        
        <ProjectStats />
        
        {/* Affichage des projets sélectionnés */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {selectedProjects.map((study) => (
            <CaseStudyCard
              key={study.id}
              study={study}
              type="development"
            />
          ))}
        </div>
        
        {/* CTA */}
        <div className="text-center">
          <Link
            to="/portfolio"
            className="group inline-flex items-center px-8 py-4 bg-gradient-primary text-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
          >
            <span className="text-lg font-semibold">Voir tous les projets</span>
            <ArrowRight className="ml-3 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </div>
      </div>
    </div>
  );
}
