import React from 'react';
import { useUser } from '../../../../contexts/UserContext';
import { journeyOptions } from './journeyOptions';
import JourneyCard from './JourneyCard';

export default function JourneyCards() {
  const { setUserType } = useUser();

  return (
    <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
      {journeyOptions.map((journey, index) => (
        <JourneyCard
          key={index}
          journey={journey}
          onSelect={() => setUserType(journey.type)}
        />
      ))}
    </div>
  );
}