import React from 'react';
import DifferentiatorsTitle from './differentiators/DifferentiatorsTitle';
import DifferentiatorsGrid from './differentiators/DifferentiatorsGrid';

export default function Differentiators() {
  return (
    <div>
      <DifferentiatorsTitle />
      <DifferentiatorsGrid />
    </div>
  );
}