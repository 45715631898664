import React from 'react';
import PortfolioHeader from '../components/portfolio/header/PortfolioHeader';
import PortfolioTabGroup from '../components/portfolio/tabs/PortfolioTabGroup';
import CaseStudyGrid from '../components/portfolio/grids/CaseStudyGrid';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { usePortfolioTabs } from '../hooks/usePortfolioTabs';
import { getStudiesByType } from '../utils/portfolio';

export default function Portfolio() {
  const { activeTab, handleTabChange } = usePortfolioTabs();
  const studies = getStudiesByType(activeTab);

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-primary/5 py-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 bg-grid-primary opacity-10" />
        <div className="relative space-y-12">
          <PortfolioHeader />
          <PortfolioTabGroup 
            activeTab={activeTab} 
            onTabChange={handleTabChange} 
          />
          <ErrorBoundary>
            <CaseStudyGrid studies={studies} type={activeTab} />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}