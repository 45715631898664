import type { Challenge } from '../types';

export const challenges: Challenge[] = [
  {
    title: "Performance limitée",
    items: [
      "Architecture monolithique",
      "Latence élevée",
      "Difficultés à scaler"
    ]
  },
  {
    title: "Coûts non optimisés",
    items: [
      "Infrastructure surdimensionnée",
      "Ressources mal utilisées",
      "Scalabilité coûteuse"
    ]
  }
];