import React from 'react';
import { Trophy, Shield, Clock } from 'lucide-react';

const exclusiveFeatures = [
  {
    icon: Shield,
    text: "Remboursement du premier sprint si non satisfait"
  },
  {
    icon: Clock,
    text: "Pénalités financières en cas de retard"
  },
  {
    icon: Trophy,
    text: "Support d'urgence 24/7 pendant 30 jours"
  }
];

export default function ExclusiveGuarantee() {
  return (
    <div className="relative transform hover:-translate-y-2 transition-all duration-300">
      <div className="absolute inset-0 bg-gradient-to-r from-yellow-100 to-amber-100 rounded-xl transform rotate-1 opacity-70" />
      <div className="relative bg-gradient-to-br from-white to-yellow-50 rounded-xl p-8 shadow-lg border border-yellow-200">
        <div className="text-center space-y-8">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-yellow-400 to-amber-500 text-white">
            <Trophy className="h-6 w-6" />
          </div>
          
          <div>
            <h3 className="text-4xl font-extrabold bg-gradient-primary text-transparent bg-clip-text inline-block">
              Triple garantie exclusive
            </h3>
          </div>
        </div>

        <div className="mt-12 space-y-6">
          {exclusiveFeatures.map((feature, index) => (
            <div 
              key={index}
              className="flex items-center space-x-4 bg-white/80 backdrop-blur p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-300"
            >
              <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-gradient-to-br from-yellow-400/20 to-amber-400/20 flex items-center justify-center text-amber-600">
                <feature.icon className="h-5 w-5" />
              </div>
              <p className="text-gray-700 font-medium">{feature.text}</p>
            </div>
          ))}
        </div>

        <div className="mt-8 p-4 bg-gradient-to-r from-yellow-50 to-transparent rounded-lg border border-yellow-100">
          <p className="text-gray-600 italic text-sm text-center">
            "Je mets ma réputation en jeu sur chaque projet. Ces garanties ne sont pas du marketing : 
            elles sont incluses dans nos contrats et juridiquement contraignantes."
          </p>
        </div>
      </div>
    </div>
  );
}