import React from 'react';
import { Plus, Minus } from 'lucide-react';

const faqs = [
  {
    question: "Quels sont vos délais moyens de livraison ?",
    answer: "Les délais varient selon la complexité du projet. Un projet moyen prend généralement 2-3 mois. Je fournis toujours une estimation détaillée après notre premier appel."
  },
  {
    question: "Comment gérez-vous les modifications en cours de projet ?",
    answer: "Les modifications mineures sont incluses. Pour les changements majeurs, nous discutons ensemble de l'impact sur le planning et le budget avant de procéder."
  },
  {
    question: "Proposez-vous un support après la livraison ?",
    answer: "Oui, je propose 1 mois de support gratuit après la livraison, puis des formules de maintenance sur mesure selon vos besoins."
  },
  {
    question: "Comment assurez-vous la qualité du code ?",
    answer: "Je suis des standards stricts de qualité : tests automatisés, revue de code, documentation complète et intégration continue. Chaque livraison passe par une batterie de tests."
  },
  {
    question: "Que se passe-t-il en cas de bug ?",
    answer: "Les bugs critiques sont corrigés gratuitement pendant 3 mois après la livraison. Je propose ensuite des contrats de maintenance pour assurer le suivi à long terme."
  }
];

export default function TechFAQ() {
  return (
    <div className="py-16 bg-gradient-to-br from-white via-gray-50 to-primary/5">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
<h2 className="text-4xl font-extrabold bg-gradient-primary text-transparent bg-clip-text inline-block mb-4">            Questions fréquentes
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Des réponses claires à tes interrogations
          </p>
        </div>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <details
              key={index}
              className="group bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <summary className="flex items-center justify-between cursor-pointer p-6">
                <h3 className="text-lg font-semibold text-secondary pr-8">
                  {faq.question}
                </h3>
                <span className="flex-shrink-0">
                  <Plus className="h-5 w-5 text-primary group-open:hidden" />
                  <Minus className="h-5 w-5 text-primary hidden group-open:block" />
                </span>
              </summary>
              <div className="px-6 pb-6">
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            </details>
          ))}
        </div>
      </div>
    </div>
  );
}