import React from 'react';
import { AlertCircle } from 'lucide-react';

const warningSignals = [
  "Tu reçois du code, mais pas de conseils stratégiques",
  "L'équipe technique parle de technologies, jamais de ROI",
  "Les développeurs exécutent sans remettre en question tes choix business"
];

export default function WarningSignals() {
  return (
    <div className="relative transform hover:-translate-y-1 transition-all duration-300">
      <div className="absolute inset-0 bg-red-100 rounded-2xl transform rotate-1 opacity-50" />
      <div className="relative bg-gradient-to-br from-red-50 to-white rounded-2xl p-8 shadow-lg border border-red-100">
        <h3 className="text-2xl font-bold text-red-700 mb-8 flex items-center">
          <AlertCircle className="h-7 w-7 mr-3" />
          3 signaux d'alarme que j'observe constamment :
        </h3>
        <div className="space-y-6">
          {warningSignals.map((signal, index) => (
            <div 
              key={index} 
              className="flex items-start space-x-4 bg-white/80 backdrop-blur p-4 rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
            >
              <span className="flex-shrink-0 w-8 h-8 bg-red-100 rounded-full flex items-center justify-center text-red-700 font-bold">
                {index + 1}
              </span>
              <p className="text-red-700 text-lg">{signal}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}