import React from 'react';
import TransformationTitle from './transformation/TransformationTitle';
import TransformationPhases from './transformation/TransformationPhases';
import ExpectedResults from './transformation/ExpectedResults';
import Differentiators from './transformation/Differentiators';

export default function FreelanceTransformation() {
  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-highlight/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <TransformationTitle />
        <div className="space-y-24">
          <TransformationPhases />
          <ExpectedResults />
          <Differentiators />
        </div>
      </div>
    </div>
  );
}