import type { ProcessPhase } from '../types';

export const developmentPhases: ProcessPhase[] = [
  {
    title: "Foundation",
    duration: "2 semaines",
    tasks: [
      "Setup monorepo",
      "Configuration tooling",
      "Design system initial"
    ]
  },
  {
    title: "Core components",
    duration: "3 semaines",
    tasks: [
      "Développement composants partagés",
      "Documentation",
      "Tests unitaires"
    ]
  },
  {
    title: "Sites",
    duration: "2 mois",
    tasks: [
      "Développement parallèle des 4 sites",
      "Intégration contenu",
      "Tests E2E"
    ]
  },
  {
    title: "Optimisation",
    duration: "2 semaines",
    tasks: [
      "Performance tuning",
      "SEO",
      "Analytics"
    ]
  }
];