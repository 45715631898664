import { nicolasCaseStudy } from './nicolas';
import { sophieCaseStudy } from './sophie';
import { audranCaseStudy } from './audran';
import { ronanCaseStudy } from './ronan'
import type { CaseStudy } from '../../types';

export const coachingCaseStudies: CaseStudy[] = [
  nicolasCaseStudy,
  sophieCaseStudy,
  audranCaseStudy,
  ronanCaseStudy
] ;