export const successPoints = [
  "Approche itérative depuis le POC",
  "Tests de charge réguliers",
  "Communication client en remote"
];

export const improvementPoints = [
  "Plus de documentation technique",
  "Meilleure estimation initiale des coûts",
  "Formation équipe plus tôt"
];