import React from 'react';
import { Sparkles } from 'lucide-react';

export default function JourneyHeader() {
  return (
    <div className="text-center space-y-12">
      {/* Main Title */}
      <div className="space-y-6">
        <div className="inline-flex items-center justify-center space-x-3">
          <div className="w-12 h-12 bg-gradient-to-br from-primary to-primary-dark rounded-xl flex items-center justify-center transform -rotate-6">
            <Sparkles className="h-6 w-6 text-white" />
          </div>
          <h1 className="text-5xl sm:text-6xl font-extrabold bg-gradient-primary text-transparent bg-clip-text">
Choisis ton parcours          </h1>
        </div>
        
  
      </div>
    </div>
  );
}