import React from 'react';

export default function TransformationTitle() {
  return (
    <div className="text-center mb-16">
      <h2 className="text-4xl font-extrabold bg-gradient-to-r from-highlight to-highlight-dark text-transparent bg-clip-text mb-4">
        Comment on va transformer ton business
      </h2>
      <p className="mt-4 text-xl text-gray-600">
        Une méthode éprouvée en 4 phases
      </p>
      <p className="mt-4 text-gray-600 max-w-2xl mx-auto">
        Découvre le processus qui a déjà transformé plus de 140 freelances en experts reconnus
      </p>
    </div>
  );
}