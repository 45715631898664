import type { Experience } from '../types';

export const nycticoraxExperience: Experience = {
  title: "Développeur Front-end / Javascript React",
  company: "Nycticorax",
  period: "Janvier 2021 - Avril 2021",
  location: "À distance",
  description: [
    "Développeur frontend sur une multitude de projets web en React, spécialisé dans la création de sites vitrine et la mise en place de bonnes pratiques de développement."
  ],
  achievements: [
    "Portfolio de 4 sites livrés en 4 mois",
    "Temps de développement moyen réduit de 40%",
    "Réutilisation de 60% des composants entre projets",
    "Parallélisation efficace de multiples projets",
    "Mise en place d'une librairie de composants partagés",
    "Standardisation des bonnes pratiques React",
    "Formation et montée en compétence de l'équipe",
    "Stack : React, TypeScript, Redux, Material-UI, Bootstrap, Styled Components"
  ]
};