import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import Navbar from './components/common/Navbar';
import Contact from './pages/ContactPage'; 
import FreelanceContact from './components/contact/FreelanceContact'
import TechContact from './components/contact/TechContact'
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import IotArchitecture from './pages/case-studies/IotArchitecture';
import ReactPortfolio from './pages/case-studies/ReactPortfolio';
import CvAnalysis from './pages/case-studies/CvAnalysis';
import CaseStudyDetails from './pages/CaseStudyDetails';
import Services from './pages/Services';
import Experiences from './pages/Experiences';
import Resources from './pages/Resources';

export default function App() {
  return (
    <UserProvider>
      <Router>
        <div className="min-h-screen bg-white">
          <Navbar />
          <div className="pt-16">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolio/development/iot-architecture" element={<IotArchitecture />} />
              <Route path="/portfolio/development/react-portfolio" element={<ReactPortfolio />} />
              <Route path="/portfolio/development/cv-analysis" element={<CvAnalysis />} />
              <Route path="/portfolio/development/:id" element={<CaseStudyDetails />} />
              <Route path="/services" element={<Services />} />
              <Route path="/experiences" element={<Experiences />} />
              <Route path="/ressources" element={<Resources />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/contact/tech" element={<TechContact />} />
              <Route path="/contact/freelance" element={<FreelanceContact />} />
            </Routes>
          </div>
        </div>
      </Router>
    </UserProvider>
  );
}
