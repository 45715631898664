import React from 'react';
import { AlertCircle } from 'lucide-react';

const challenges = [
  {
    title: "Processus manuel inefficace",
    items: [
      "30 minutes par CV en moyenne",
      "Risque d'erreurs humaines",
      "Inconsistance dans l'extraction"
    ]
  },
  {
    title: "Difficultés techniques",
    items: [
      "Formats de CV hétérogènes",
      "Données non structurées",
      "Besoin d'analyse sémantique"
    ]
  }
];

export default function InitialContext() {
  return (
    <div className="space-y-8">
      <div className="bg-white rounded-xl p-8 shadow-lg">
        <h2 className="text-2xl font-bold text-secondary mb-6">Contexte du projet</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold text-primary mb-4">Situation initiale</h3>
            <ul className="space-y-3">
              <li className="flex items-center space-x-2">
                <span className="w-2 h-2 bg-primary rounded-full" />
                <span>Client : Axe Capital</span>
              </li>
              <li className="flex items-center space-x-2">
                <span className="w-2 h-2 bg-primary rounded-full" />
                <span>Besoin : Automatisation de l'analyse des CV</span>
              </li>
              <li className="flex items-center space-x-2">
                <span className="w-2 h-2 bg-primary rounded-full" />
                <span>Volume : 100+ CV par semaine</span>
              </li>
              <li className="flex items-center space-x-2">
                <span className="w-2 h-2 bg-primary rounded-full" />
                <span>Délai : 3 mois</span>
              </li>
            </ul>
          </div>

          <div className="space-y-6">
            {challenges.map((challenge, index) => (
              <div key={index} className="bg-red-50 rounded-lg p-4">
                <div className="flex items-start space-x-3">
                  <AlertCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-semibold text-red-700 mb-2">{challenge.title}</h4>
                    <ul className="space-y-2">
                      {challenge.items.map((item, i) => (
                        <li key={i} className="flex items-start space-x-2 text-red-600">
                          <span className="w-1.5 h-1.5 bg-red-500 rounded-full mt-2" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}