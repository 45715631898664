import React from 'react';

export default function PortfolioHeader() {
  return (
    <div className="text-center">
      <h1 className="text-4xl font-extrabold sm:text-5xl bg-gradient-primary text-transparent bg-clip-text inline-block">
        Études de cas
      </h1>
      <p className="mt-4 text-xl text-gray-600">
        Découvrez mes réalisations et success stories
      </p>
    </div>
  );
}