import React from 'react';
import { Code, Palette, Rocket } from 'lucide-react';

export default function Solution() {
  const solutions = [
    {
      icon: Code,
      title: "Architecture monorepo",
      items: [
        "Mise en place de Turborepo",
        "Bibliothèque de composants partagée",
        "Configuration centralisée"
      ]
    },
    {
      icon: Palette,
      title: "Design system modulaire",
      items: [
        "Composants de base réutilisables",
        "Thèmes personnalisables par marque",
        "Documentation Storybook complète"
      ]
    },
    {
      icon: Rocket,
      title: "Pipeline CI/CD optimisé",
      items: [
        "Tests automatisés",
        "Preview deployments",
        "Déploiement automatique sur Vercel"
      ]
    }
  ];

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-secondary">Solution mise en place</h2>
      
      <div className="grid md:grid-cols-3 gap-8">
        {solutions.map((solution, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-lg">
            <div className="flex flex-col h-full">
              <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
                <solution.icon className="h-6 w-6 text-primary" />
              </div>
              
              <h3 className="text-xl font-semibold text-primary mb-4">{solution.title}</h3>
              
              <ul className="space-y-3">
                {solution.items.map((item, i) => (
                  <li key={i} className="flex items-start space-x-2">
                    <span className="w-1.5 h-1.5 bg-primary rounded-full mt-2" />
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-primary/5 rounded-xl p-6">
        <pre className="text-sm text-gray-800 overflow-x-auto">
          <code>{`// Exemple de composant réutilisable avec thème
interface ButtonProps {
  variant: 'primary' | 'secondary';
  size: 'sm' | 'md' | 'lg';
  brand: 'brand1' | 'brand2' | 'brand3' | 'brand4';
}

const Button = styled.button<ButtonProps>\`
  background: \${props => props.theme.brands[props.brand].colors[props.variant]};
  padding: \${props => props.theme.sizes[props.size]};
  // Autres styles...
\`;`}</code>
        </pre>
      </div>
    </div>
  );
}